import React, { useEffect, useState } from 'react';
import { useParams } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import _global from '../api/global';


const File = () => {
    const { t } = useTranslation();
    let { name } = useParams();

      
    useEffect(() => {

    }, [])


    console.warn("name::", name);

    return (
        <object data={`${_global.config.api}/uploads/${name}`} type="application/pdf" 
        width="100%" 
        height="100%" 
        className="min-vh-100"
        style={{ position: 'absolute',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0}}
        /> 
    );
}

export default File;

import React, { useEffect, useState } from 'react';
import { Document, Page, pdfjs  } from 'react-pdf';
import OrdersApi from '../api/orders';
 
pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/legacy/build/pdf.worker.min.js`;
//pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const PdfReader = () => {
  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);
  const [pdfFile, setPdfFile] = useState('');

  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
  }

  useEffect(() => {

    setPdfFile('');


  }, [])

  //console.log('pdfFiles::', pdfFile)

  return (
    <div>
      <Document 
      file={{url:'http://localhost:8001/pdf/orders/1'}} 
      onLoadSuccess={onDocumentLoadSuccess}
      width="100%"
      height="100%"
      >
        <Page pageNumber={pageNumber} />
      </Document>
      <p>
        Page {pageNumber} of {numPages}
      </p>
    </div>
  );
}

export default PdfReader;
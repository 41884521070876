import $ from 'jquery';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Menu from './menu';
import moment from 'moment'

import {
    faTrashAlt,
    faPowerOff,
    faEdit,
    faFloppyDisk,
    faCartShopping,
    faFilePdf
} from '@fortawesome/free-solid-svg-icons';
const config = require('../config/production').default;



function Table(props) {
    const { t } = useTranslation();
    let {
        data,
        deleteProduct,
        setName,
        setId,
        setDetail,
        setPrice,
        setCategoryId,
        setStatus,
        setType,
        submitForm,
        OrdersApi
    } = props;
    const [productId, setProductId] = useState('');
    const [orders, setOrders] = useState([]);
    const [line, setLine] = useState([]);

    useEffect(() => {
        setOrders(data)
    }, [data])





    const modalShow = async (e, item) => {
        console.log('element::', e, 'item::', item);
        let orderLine = await OrdersApi.getOrderLine(item.id)
        console.log('order line::', orderLine)
        setLine(orderLine);

        // let html = `<div>
        //                 Detail: ${item.detail}
        //             </div>`;

        //$('body').find('.modal-product-detail').html(html)
    }


    const setDeleteItem = (e, item) => setProductId(item.id);

    const comfirm = () => {
        deleteProduct(productId);
    }

    const statusRender = (status) => {
        let className = 'text-success';
        let text = "";
        switch (parseInt(status)) {
            case 0:
                className = 'text-secondary';
                text = t('Created');
                break;
            case 1:
                className = 'text-warning';
                text = t('Pending');
                break;
            case 2:
                className = 'text-success';
                text = t('Confirmed');
                break;
            case 3:
                className = 'text-info';
                text = t('Waiting for payment');
                break;
            case 1:
                className = 'text-success';
                text = t('Paid');
                break;

            case 4:
                className = 'text-muted';
                text = t('Waiting for the transfer slip');
                break;
            case 5:
                className = 'text-success';
                text = t('Paid');
                break;
            case 9:
                className = 'text-muted';
                text = t('Cancled');
                break;

            default:
                className = 'text-danger';
                text = t('Not set');
                break;
        }
        return { class: className, text: text }
    }

    const format = (num) => {
        return Number(num).toLocaleString("en-US", { maximumFractionDigits: 2, minimumFractionDigits: 2 });
    }

    console.log('line::', line)
    return (
        <div>
            <h5>{t('Orders List')}</h5>
            <div className="modal fade modal-fullscreen" id="addModalNew" tabIndex={-1} role="dialog" aria-hidden="true">
                <div className="modal-dialog modal-fullscreen" role="document">
                    <div className="modal-content">
                        <div className="modal-header bg-none text-white">
                            <h5 className="modal-title modal-add-title text-info"><FontAwesomeIcon icon={faCartShopping} /> {t('Order detail')}</h5>
                            <button className="close" type="button" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">×</span>
                            </button>
                        </div>
                        <div className="modal-body modalNew-add-detail">
                            <div className="row">
                                <div className="col">
                                    {t('Product detail')}
                                    <table className="table table-bordered" width="100%" cellSpacing={0}>
                                        <thead>
                                            <tr>
                                                <th>{t('#')}</th>
                                                <th>{t('Product name')}</th>
                                                <th className='text-right'>{t('Qty')}</th>
                                                <th className='text-right'>{t('Price/Pcs')}</th>
                                                <th className='text-right'>{t('Total Price')}</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                line.length > 0 && line?.map((line, i) =>
                                                    <tr key={line.id} id={line.orders + '-' + line.id}>
                                                        <td >{i + 1}</td>
                                                        <td> {line.name}</td>
                                                        <td className='text-right'>{format(line.qty)}</td>
                                                        <td className='text-right'>{format(line.price)}</td>
                                                        <td className='text-right'>{format(line.qty * line.price)}</td>
                                                    </tr>
                                                )
                                            }
                                        </tbody>
                                    </table>
                                </div>
                                <div className="col">
                                    {t('Payment')}
                                </div>
                            </div>
                        </div>
                        <div className="modal-footer bg-none ">
                            <button className="btn btn-outline-success btn-sm" type="button" onClick={submitForm}><FontAwesomeIcon icon={faFloppyDisk} /> {t('save')}</button>
                            <button className="btn btn-outline-danger btn-sm" type="button" data-dismiss="modal"><FontAwesomeIcon icon={faPowerOff} /> {t('close')}</button>
                        </div>
                    </div>
                </div>
            </div>
            <div className="modal fade" id="modalConfirm" tabIndex={-1} role="dialog">
                <div className="modal-dialog" role="document">
                    <div className="modal-content">
                        <div className="modal-header bg-danger text-white">
                            <h5 className="modal-title">Confirm</h5>
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">×</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            <p>{t('comfirn delete')}</p>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-danger" onClick={comfirm} data-dismiss="modal" ><FontAwesomeIcon icon={faTrashAlt} /> {t('delete')}</button>
                            <button type="button" className="btn btn-secondary" data-dismiss="modal"><FontAwesomeIcon icon={faPowerOff} /> {t('cancel')}</button>
                        </div>
                    </div>
                </div>
            </div>
            <div className="card shadow mb-4">
                <div className="card-header py-3">
                    <h6 className="m-0 font-weight-bold text-primary">{t('Order list')}</h6>
                </div>
                <div className="card-body">
                    <div className="table-responsive">
                        <table className="table table-bordered" id="dataTable" width="100%" cellSpacing={0}>
                            <thead>
                                <tr>
                                    <th>{t('Order id')}</th>
                                    <th>{t('Start date')}</th>
                                    <th>{t('End date')}</th>
                                    <th className='text-right'>{t('Price')}</th>
                                    <th>{t('Status')}</th>
                                    <th className="text-right">{t('Actions')}</th>
                                </tr>
                            </thead>

                            <tbody>
                                {
                                    orders?.map((order, key) =>
                                        <tr key={order.id}>
                                            <td >
                                                <p>{order.id}</p>
                                                <div className='font-10'>{moment(order.order_date).format('YYYY-MM-DD')}</div>
                                            </td>
                                            <td className='font-12'>{moment(order.start_date).format('YYYY-MM-DD')}</td>
                                            <td className='font-12'>{moment(order.end_date).format('YYYY-MM-DD')}</td>
                                            <td className='text-right font-11'>{order.amount}</td>
                                            <td className={`font-10 ${statusRender(order.status).class} `}>
                                                <p>{statusRender(order.status).text}</p>
                                                <div className='font-13'>
                                                    <FontAwesomeIcon icon={faFilePdf} className="text-danger" />
                                                </div>
                                            </td>
                                            <td className="text-right font-10">
                                                <div className="btn-group" role="group">
                                                    <button id="btnGroupDrop1" type="button" className="btn btn-default dropdown-toggle btn-sm" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                        {t('Actions')}
                                                    </button>
                                                    <div className="dropdown-menu" aria-labelledby="btnGroupDrop1">
                                                        <button className="dropdown-item" onClick={(e) => modalShow(e, order)} data-toggle="modal" data-target="#addModalNew"><FontAwesomeIcon icon={faEdit} /> {t('Detail')} </button>
                                                        <button className="dropdown-item" onClick={(e) => setDeleteItem(e, order)} data-toggle="modal" data-target="#modalConfirm"><FontAwesomeIcon icon={faPowerOff} /> {t('Delete item')} </button>
                                                    </div>
                                                </div>
                                            </td>
                                        </tr>
                                    )
                                }
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>

        </div>
    )
}

export default Table;


import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';

import Home from './pages/home';
import Products from './pages/products/products';
import Detail from './pages/detail';
import NotFound from './pages/NotFound';
import Login from './pages/login';
import Register from './pages/register';
import ForgotPassword from './pages/forgot-password';
import Category from './pages/category';
import Orders from './pages/orders';
import Payment from './pages/payment';
import OrdersPdf from './pages/orders-pdf';
import Inventory from './pages/inventory/index';
import ItemJournal from './pages/inventory/journal';
import ReturnReceive from './pages/inventory/return-receive';
import ReceiveAdjust from './pages/inventory/receive-adjust';
import AssetsViews from './pages/products/views';
import File from './pages/file';

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/assets/list" element={<Products />} />        
        <Route path="/assets/:id/:name" element={<Detail/>}  /> 
        <Route path="/assets/views/:id/:name" element={<AssetsViews/>}  /> 

        <Route path="/login" element={<Login />} />     
        <Route path="/register" element={<Register />} /> 
        <Route path="/category/list" element={<Category />} />      
        <Route path="/orders" element={<Orders />} />   
        <Route path="/orders/pdf" element={<OrdersPdf />} />   
        <Route path="/payment" element={<Payment />} />  
        <Route path="/forgot-password" element={<ForgotPassword />} />     
        
        <Route path="/inventory" element={<Inventory />} />        
        <Route path="/inventory/journal" element={<ItemJournal />} />    
        <Route path="/inventory/return" element={<ReturnReceive />} />    
        <Route path="/inventory/adjust/:mode" element={<ReceiveAdjust />} />      

        <Route path="/file/:name" element={<File />} />  
            
        <Route path="*" element={<NotFound/>} />
      </Routes>
    </Router>
  );
}

export default App;


import React, { useEffect, useState } from 'react';
import { Link, NavLink } from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faBoxOpen,
    faCartShopping,
    faDollarSign,
    faClipboardList,
    faCubes,
    faObjectGroup,
    faWarehouse,
    faCartFlatbed,
    faTruckRampBox,
    faRoute,
    faSliders,
    faChartColumn,
    faRectangleList
} from '@fortawesome/free-solid-svg-icons'
import { useTranslation } from 'react-i18next';




const SideBar = () => {
    const { t } = useTranslation();
    const { innerWidth: width, innerHeight: height } = window;
    //console.log('width::', width)
    const [collapseProduct, setCollapseProduct] = useState(width < 768 ? '' : 'show');

    useEffect(() => {

    }, [])

    const navClick = () => {

    }

    const activeMenuProduct = (isActive) => {
        if (isActive) {
            setCollapseProduct('show')
            return 'collapse-item active';
        } else {
            setCollapseProduct('')
            return 'collapse-item';
        }
    }

    //document.body.classList.add('modal-open');
    return (

        <ul className={`navbar-nav bg-gradient-success sidebar sidebar-dark accordion ${width < 768 ? 'toggled' : ''}`} id="accordionSidebar">
            <a className="sidebar-brand d-flex align-items-center justify-content-center" href="#">
                <div className="sidebar-brand-text mx-3">GEC <sup>tm</sup></div>
            </a>
            <hr className="sidebar-divider my-0" />
            <li className="nav-item active">
                <Link to={`/`} className="nav-link">
                    <i className="fas fa-fw fa-tachometer-alt" />
                    <span>Dashboard</span>
                </Link>
            </li>
            <hr className="sidebar-divider" />
            <div className="sidebar-heading">
                Interface
            </div>
            <li className="nav-item">
                <a className="nav-link collapsed" href="#" data-toggle="collapse" data-target="#collapseProduct" aria-expanded="true" aria-controls="collapseTwo">
                    <FontAwesomeIcon icon={faRectangleList} />
                    <span> {t('Assets')}</span>
                </a>
                <div id="collapseProduct" className={`collapse ${collapseProduct}`} aria-labelledby="headingTwo" data-parent="#accordionSidebar">
                    <div className="bg-white py-2 collapse-inner rounded">
                        <h6 className="collapse-header">{t('Product Setting')}:</h6>
                        <NavLink to={`/assets/list`} className="collapse-item">
                            <FontAwesomeIcon icon={faRectangleList} /> {t('Assets List')}
                        </NavLink>
                        <NavLink to={`/category/list`} className="collapse-item" >
                            <FontAwesomeIcon icon={faObjectGroup} /> {t('Category Assets')}
                        </NavLink>
                    </div>
                </div>
            </li>


            <li className="nav-item">
                <a className="nav-link collapsed" href="#" data-toggle="collapse" data-target="#collapseInven" aria-expanded="true" aria-controls="collapseUtilities">
                    <FontAwesomeIcon icon={faSliders} />
                    <span> {t('Adjust')}</span>
                </a>
                <div id="collapseInven" className={`collapse ${collapseProduct}`} aria-labelledby="headingUtilities" data-parent="#accordionSidebar">
                    <div className="bg-white py-2 collapse-inner rounded">
                        <h6 className="collapse-header">{t('warehouse')}:</h6>
                        {/* 
                        <NavLink to="/inventory/journal" className="collapse-item" >
                            <FontAwesomeIcon icon={faCartFlatbed} className="text-info" /> {t('item journal')}
                        </NavLink>

                        <NavLink to="/inventory/return" className="collapse-item" >
                            <FontAwesomeIcon icon={faTruckRampBox} className="text-warning" /> {t('return receive')}
                        </NavLink> */}

                        <NavLink to="/inventory/adjust/receive" className="collapse-item" >
                            <FontAwesomeIcon icon={faRoute} className="text-success" /> {t('receive journal')}
                        </NavLink>

                        <NavLink to="/inventory/adjust/stock" className="collapse-item" >
                            <FontAwesomeIcon icon={faSliders} className="text-danger" /> {t('inventory adjust')}
                        </NavLink>


                        <h6 className="collapse-header"> {t('report')}</h6>
                        <div className="bg-white py-2 collapse-inner rounded">
                            <NavLink to="/inventory" className="collapse-item" >
                                <FontAwesomeIcon icon={faChartColumn} className="text-primary" /> {t('transactions')}
                            </NavLink>
                        </div>

                    </div>
                </div>



            </li>

            <div className="text-center d-none d-md-inline">
                <button className="rounded-circle border-0" id="sidebarToggle" onClick={navClick} />
            </div>

        </ul>

    )
}

export default SideBar; 
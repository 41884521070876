import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import UserApi from '../../api/user';
import ApprovalSetupApi from '../../api/approval_setup';
import ApprovalApi from '../../api/approval';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit, faEye, faTrashAlt } from '@fortawesome/free-solid-svg-icons';

const Btn = ({ row, view, setDeleteRow, edit }) => {
    const { t } = useTranslation();


    return (
        <div>
            <button
                className="btn btn-primary btn-xs ml-1"
                onClick={() => view(row)} >
                <FontAwesomeIcon icon={faEye} /> {t('view')}
            </button>
            <button
                className="btn btn-warning btn-xs ml-1"
                onClick={() => row.status === 4
                    ? view(row)
                    : edit(row)
                } >
                <FontAwesomeIcon icon={faEdit} /> {t('edit')}
            </button>
            <button
                className="btn btn-danger btn-xs ml-1"
                onClick={() => setDeleteRow(row)}
                data-toggle="modal"
                data-target="#modalDeleteJournal">
                <FontAwesomeIcon icon={faTrashAlt} /> {t('delete')}
            </button>
        </div>
    );
}

export default Btn;

import { t } from 'i18next';
import React, { useEffect } from 'react';
import _global from './global';
import DataTable from 'react-data-table-component';
import ArrowDownward from '@material-ui/icons/ArrowDownward';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faEdit, faPowerOff, faTrashAlt
} from '@fortawesome/free-solid-svg-icons';
import _ from 'lodash';


const search = async (from, to, item) => {
    try {
        let response = await _global.client.get(`/v1/inventory`, {
            params: {
                from: from,
                to: to,
                item: item
            }
        });

        let list = [];
        for (let row of response.data) {
            let payloads = row.payload ? JSON.parse(row.payload) : [];
            payloads.payload = typeof payloads.payload === 'string' ? JSON.parse(payloads.payload) : [];
            list.push({
                ...row,
                payload: payloads
            });
        }

        return { data: list };
    } catch (error) {
        console.error(error);
    }
}

const searchByItem = async (from, to, item) => {
    try {
        let response = await _global.client.get(`/v1/inventory`, {
            params: {
                from: from,
                to: to,
                item: item
            }
        });
        //console.log('response::', response.data)
        return response.data;
    } catch (error) {
        console.error(error);
    }
}

const getData = async () => {
    try {
        let response = await _global.client.get(`/v1/customer`);
        console.log('response::', response.data)
        return response.data;
    } catch (error) {
        console.error(error);
    }
}

const remove = async (id) => {
    try {
        return await _global.client.delete(`/v1/customer/${id}`);
    } catch (e) {
        console.error('error::', e);
    }
}


const newData = async (body) => {
    try {
        body.status = 1;
        return await _global.client.post(`/v1/customer`, body);
    } catch (e) {
        console.error('error::', e);
    }
}

const updateData = async (body, id) => {
    try {
        body.status = 1;
        return await _global.client.patch(`/v1/customer/${id}`, body);
    } catch (e) {
        console.error('error::', e);
    }
}


const TableList = (props) => {

    return (
        <DataTable
            title={t('Users')}
            noDataComponent={t('There are no records to display')}
            pagination
            paginationPerPage={50}
            paginationRowsPerPageOptions={[10, 20, 50, 100, 200, 300]}
            selectableRowsComponentProps={{ indeterminate: isIndeterminate => isIndeterminate }}
            sortIcon={<ArrowDownward />}
            dense
            columns={[
                {
                    name: t('username'),
                    selector: row => row.username,
                    sortable: true,
                    id: "username"
                },

                {
                    name: t('Action'),
                    selector: row => row.id,
                    id: "action",
                    width: '150px',
                    cell: (item) => <div className='w-100 text-right'>
                        <button className="btn btn-warning btn-xs ml-1" ><FontAwesomeIcon icon={faEdit} /> {t('edit')} </button>
                        <button className="btn btn-danger btn-xs ml-1"  ><FontAwesomeIcon icon={faTrashAlt} /> {t('delete')} </button>
                    </div >
                }

            ]}
            data={props.data || []}
            sortFunction={_global.customSort}
            defaultSortFieldId="code"
            customStyles={{
                header: {
                    style: {
                        minHeight: '56px',
                    },
                },
                headCells: {
                    style: {
                        '&:not(:last-of-type)': {
                            borderTopStyle: 'solid',
                            borderTopWidth: '1px',
                            borderTopColor: '#ccc',
                            borderLeftStyle: 'solid',
                            borderLeftWidth: '1px',
                            borderLeftColor: '#ccc',
                            borderTop: "5px solid black",
                            borderBottom: "1px double #ccc"
                        },
                        "&:last-child": {
                            borderTopStyle: 'solid',
                            borderTopWidth: '1px',
                            borderTopColor: '#ccc',
                            borderRightStyle: 'solid',
                            borderRightWidth: '1px',
                            borderRightColor: '#ccc',
                            borderLeftStyle: 'solid',
                            borderLeftWidth: '1px',
                            borderLeftColor: '#ccc',
                            borderTop: "5px solid black",
                            borderBottom: "1px double #ccc"
                        }
                    },
                },
                rows: {
                    style: {
                        borderStyle: 'solid',
                        borderWidth: '1px',
                        borderColor: '#ccc'
                    },
                },
                cells: {
                    style: {
                        '&:not(:last-of-type)': {
                            borderRightStyle: 'solid',
                            borderRightWidth: '1px',
                            borderRightColor: '#ccc'
                        },
                    },
                },
            }}
            highlightOnHover
            pointerOnHoverF
            {...props}
        />
    );


}


const FormAddData = ({ data, setData, setLoading, formData, setFormData }) => {


    useEffect(() => {

    }, [data])

    return <>
        <div className={`d-none`}>
            <div className="row">
                <div className="col-6">
                    <label htmlFor='product-price'>{t('customers code')}:</label>
                    <input className="form-control" id={'code'} />
                </div>
            </div>
        </div>
    </>
}


const InventoryApi = {
    search,
    searchByItem,
    getData,
    TableList,
    FormAddData,
    remove,
    newData,
    updateData
};

export default InventoryApi;
import $ from 'jquery';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';


function Modal() {
    const { t } = useTranslation();
    const navigate = useNavigate();

    useEffect(() => {

    }, [])

    const logOut = () => {
        $('body').find('button.close').click();
        setTimeout(() => {
            localStorage.removeItem('token');
            navigate('/login', { replace: true });
        },100)
       
    }

    return (
        <div>
            <div 
            className="modal fade" 
            id="logoutModal" 
            tabIndex={-1} 
            role="dialog" 
            aria-hidden="true" 
            data-keyboard="false"
            data-backdrop="static">
                <div className="modal-dialog" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title">Ready to Leave?</h5>
                            <button className="close" type="button" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">×</span>
                            </button>
                        </div>
                        <div className="modal-body">Select "Logout" below if you are ready to end your current session.</div>
                        <div className="modal-footer">
                            <button className="btn btn-secondary" type="button" data-dismiss="modal">Cancel</button>
                            <button className="btn btn-primary" onClick={logOut}>Logout</button>
                        </div>
                    </div>
                </div>
            </div>
            <div className="modal fade " id="detailModal" tabIndex={-1} role="dialog" aria-hidden="true">
                <div className="modal-dialog modal-lg" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title modal-product-title">Products</h5>
                            <button className="close" type="button" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">×</span>
                            </button>
                        </div>
                        <div className="modal-body modal-product-detail"></div>
                        <div className="modal-footer">
                            <button className="btn btn-secondary" type="button" data-dismiss="modal">{t('cancel')}</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Modal;


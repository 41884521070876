import { t } from 'i18next';
import React, { useEffect, useState } from 'react';
import BootstrapSwitchButton from 'bootstrap-switch-button-react'


const axios = require('axios').default;
const config = require(`../config/${process.env.NODE_ENV}`).default;

let client = axios.create({
    baseURL: config.api,
});

client.interceptors.request.use(
    config => {
        if (localStorage.getItem('token')) {
            config.headers['Authorization'] = `Bearer ${localStorage.getItem('token')}`;
        } else {
            localStorage.removeItem('token')
        }
        return config;
    },
    error => {
        return Promise.reject(error);
    }
);

client.interceptors.response.use((response) => {
    return response;
}, (error) => {
    console.error('error.response.status::', error.response)
    if (error.response.status === 401) {
        localStorage.removeItem('token')
    }
    return Promise.reject(error.message);
});

const Add = (props) => {
    const [category, setCategory] = useState([]);
    let {
        id,
        name,
        detail,
        price,
        categoryId,
        status,
        type,
        setName,
        setDetail,
        setPrice,
        setCategoryId,
        setStatus,
        handleChange,
        setType,
    } = props;

    useEffect(() => {
        let cat = localStorage.getItem('category') ? JSON.parse(localStorage.getItem('category')) : [];
        setCategory(cat);
    }, [])

    return <div>
        <div className="row">
            <input type="hidden" value={id} name="id" />
            <div className="col-6">
                <label htmlFor='product-name'>{t('Name')}:</label>
                <input type="text" value={name} className="form-control" id="product-name" name="name" placeholder={t("Product Name")} onChange={e => setName(e.target.value)} />
            </div>
            <div className="col-2">
                <label htmlFor='product-name'>{t('Item type')}:</label>
                <select className="form-control" id="product-name" name="item_type" value={type} onChange={e => setType(e.target.value)} >
                    <option value={0}>{t('Goods')}</option>
                    <option value={1}>{t('Semi Product')}</option>
                    <option value={2}>{t('Material')}</option>
                </select>
            </div>
            <div className="col">
                <label htmlFor='product-price'>{t('price')} {t('sale/rent')}:</label>
                <input type="text" value={price} className="form-control" id="product-price" name="price" placeholder={t("Detail")} onChange={e => setPrice(e.target.value)} />
            </div>
        </div>
        <div className="row mt-2">
            <div className="col-8">
                <label htmlFor='product-price'>{t('Detail')}:</label>
                <textarea className="form-control" value={detail} id="exampleFormControlTextarea1" rows="3" onChange={e => setDetail(e.target.value)} />
            </div>
            <div className="col">
                <label htmlFor='product-category'>{t('Category')}:</label>
                <select className="custom-select" id="product-category" name="category" value={categoryId} onChange={e => setCategoryId(e.target.value)}>
                    {
                        category?.map((el, index) => <option key={index} value={el.id}>{el.name}</option>)
                    }
                </select>
            </div>
        </div>
        <div className="row mt-2 justify-content-end">
            <div className="col-4">
                <label htmlFor='validatedCustomFile'>{t('Image')}:</label>
                <div className="custom-file">
                    <label className="custom-file-label" htmlFor="validatedCustomFile">Choose image...</label>
                    <input
                        type="file"
                        name="file"
                        className="custom-file-input"
                        id="validatedCustomFile"
                        onChange={handleChange}
                        accept=".jpg,.png"
                    />
                    <div className="invalid-feedback">Example invalid custom file feedback</div>
                </div>
            </div>
        </div>
        <div className="row mt-2 justify-content-end">
            <div className="col-4">
                <BootstrapSwitchButton
                    checked={status === 'Active' ? true : false}
                    onlabel='Active'
                    offlabel='Disable'
                    onChange={(checked) => { setStatus(checked ? 'Active' : 'Disabled') }}
                    size="sm"
                    width={100}
                    onstyle="success"
                />
            </div>
        </div>
    </div>
}

export default {
    search: async ({ article, ref_id, group }) => {
        try {
            const response = await client.get(`/v1/item/props`, {
                params: {
                    article: article,
                    ref_id: ref_id,
                    group: group
                }
            });
            let data = response.data && response.data?.map((el) => ({
                ...el,
                att_file: el.att_file
                    ? JSON.parse(el.att_file)
                    : {}
            }));

            // let newData = data.map((el) => ({
            //     ...el,
            //     att_file: {
            //         ...el.att_file,
            //         setting: el.att_file.setting ? JSON.parse(el.att_file.setting) : {}
            //     }
            // }));

            console.info('<< res ss::', data);
            return data;
        } catch (e) {
            console.error('error search::', e);
        }
    },
    post: async (body) => {
        try {
            const response = await client.post(`/v1/item/props/`, body);
            return response.data;
        } catch (e) {
            console.error('error post::', e);
        }
    },
    get: async (id) => {
        try {
            const response = await client.get(`/v1/item/props/${id}`);
            return response.data;
        } catch (e) {
            console.error('error get::', e);
        }
    },
    getByGroup: async (cat) => {
        try {
            const response = await client.get(`/v1/item/props/${cat}`);
            return response.data;
        } catch (e) {
            console.error('error getByGroup::', e);
        }
    },
    delete: async (id) => {
        try {
            return await client.delete(`/v1/item/props/${id}`);
        } catch (e) {
            console.error('error remove::', e);
        }
    },
    Add
};
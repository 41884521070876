import { Link } from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useTranslation } from 'react-i18next';

import config from '../config/production';


function Header(obj) {
    const { t } = useTranslation();
    return (
        <header className="menu-bar ">
            <div className="row  d-flex align-items-center">

                {
                    config.menu.map(m => {
                        return (
                            <div className="col items" key={m.id}>
                                <Link to={m.url} className="text-decoration-none text-white">
                                    <FontAwesomeIcon icon={m.icon} />
                                    <div className="text-link">  {t(m.text)}</div>
                                </Link>
                            </div>
                        )
                    })
                }

            </div>
        </header>
    )
}

export default Header;
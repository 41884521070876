import React, { useEffect, useState } from 'react';
import { t } from 'i18next';
import { useParams, useSearchParams } from "react-router-dom";
import Template from '../../component/common/template';
import Waiting from '../../component/waiting';
import _global from '../../api/global';
import rbac from '../../api/rbac';
import ItemPropPerties from '../../component/itemProperties';
import ImageUploads from '../../component/imageUploads';
import product from '../../api/products';

const Views = () => {
    let { id, name } = useParams();
    const [loading, setLoading] = useState(false);
    const [item, setItem] = useState(null);
    const [searchParams, setSearchParams] = useSearchParams();
    const [modify, setModify] = useState(false);

    const fetchData = async (id) => {
        try {
            let assets = await product.getProduct(id);
            console.warn('component assets::', assets)
            setItem(assets);
        } catch (e) {
            console.error('error get product::', e);
        }
    }

    useEffect(() => {
        fetchData(id).catch(console.error);
        if(searchParams.get("modify") && rbac.getPermission('admin')){
            setModify(true);
        }else{
            setModify(false);
        }
    }, []);

    const updateAssets = async (calback) => {
        try {
            //console.warn('callback::', calback);
            let images = item.images;
            images.push(calback.name);
            let body = {
                images: images.join(",")
            };

            console.info('body update::', body);
            await product.patch(body, id);
        } catch (e) {
            console.error('error get product::', e);
        }
    }

    const updateImages = async (calback) => {
        console.warn('<< calback::', calback);
        try {
            console.warn('callback::', calback);
            let body = {
                images: calback.join(",")
            };

            console.info('body update::', body);
            await product.patch(body, id);

            let updateItem = {
                ...item,
                images: calback
            };
            console.info('updateItem"', updateItem)
            setItem(updateItem);

        } catch (e) {
            console.error('error get product::', e);
        }
    }

    return (
        <Template >
            <Waiting loading={loading} />

            <div>
                {t('บันทึกทรัพย์สิน')}
            </div>
            <div className='row  mt-3'>
                <div className='col-md-8'>
                    {t('Assets Information')} [{item?.id}]

                    <div className='row small mt-2'>
                        <div className='col-4 text-sm'>{t('Assets Code')}: {item?.code}</div>
                        <div className='col-8'>{t('Assets Status')}:  {_global.assetsStatus(item?.status, t)}</div>
                    </div>

                    <div className='row small mt-2'>
                        <div className='col-4 text-sm'>{t('Mongo Assets Code')}:  {item?.code}</div>
                        <div className='col-8'>{t('Assets Status')}: ปกติ</div>
                    </div>

                    <div className='row small mt-2'>
                        <div className='col-12 text-sm'>{t('Assets Name')}:  {item?.name}</div>
                    </div>

                    <div className='row small mt-2'>
                        <div className='col-12 text-sm'>{t('Assets Type')}:  {item?.type}</div>
                    </div>

                    <div className='row small mt-2'>
                        <div className='col-12 text-sm'>{t('Series Number')}:  {item?.code}</div>
                        <div className='col-6 text-sm'>
                            <ItemPropPerties controller={{
                                group_code: 'C00',
                                id: id,
                                name: name,
                                border: false,
                                modify: modify
                            }} />
                        </div>
                    </div>


                    <ImageUploads controller={{
                        item: item,
                        callBack: updateAssets,
                        updateImages: updateImages,
                        modify: modify
                    }} />

                    <div className='mb-5'>
                        <ItemPropPerties controller={{
                            group_code: 'C02',
                            id: id,
                            name: name,
                            border: true,
                            modify: modify
                        }} />
                    </div>

                </div>

                <div className='col-md-4'>
                    <div>
                        <ItemPropPerties controller={{
                            group_code: 'C01',
                            id: id,
                            name: name,
                            border: true,
                            modify: modify
                        }} />
                    </div>

                    <div className='mt-5'>
                        <ItemPropPerties controller={{
                            group_code: 'C03',
                            id: id,
                            name: name,
                            border: true,
                            modify: modify
                        }} />

                    </div>
                </div>


            </div>
        </Template>
    );
}

export default Views;

import React from 'react';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faFloppyDisk, faPowerOff
} from '@fortawesome/free-solid-svg-icons';

const ModalConfirm = (props) => {
    const { submitConfirm, screen, name } = props;
    const { t } = useTranslation();


    return (
        <div className={`modal fade ${screen}`} id={`addModalConfirm-${name}`} tabIndex={-1} role="dialog" aria-hidden="true" >
            <div className="modal-dialog modal-md" role="document">
                <div className="modal-content">
                    <div className="modal-header bg-warning text-white">
                        <h5 className="modal-title modal-add-title">{t('Confirm')}</h5>
                        <button className="close" type="button" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">×</span>
                        </button>
                    </div>
                    <div className="modal-body modalNew-add-detail">
                        {props.children}
                    </div>
                    <div className="modal-footer bg-warning ">
                        <button className="btn btn-success btn-sm" type="button" onClick={submitConfirm}><FontAwesomeIcon icon={faFloppyDisk} /> {t('Confirm')}</button>
                        <button className="btn btn-secondary btn-sm" id={`close-addModalConfirm-${name}`} type="button" data-dismiss="modal"><FontAwesomeIcon icon={faPowerOff} /> {t('Cancel')}</button>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default ModalConfirm;

import $ from 'jquery';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Menu from './menu';

import {
    faTrashAlt,
    faPowerOff,
    faEdit
} from '@fortawesome/free-solid-svg-icons';
const config = require(`../config/${process.env.NODE_ENV}`).default;


function Table(props) {
    let {
        data,
        deleteData,

        setId,
        setName,
        setDetail,
        setImg,
        setAlt,
        setList
    } = props;
    const [categoryId, setCategoryId] = useState('');
    const [items, setItems] = useState([]);

    useEffect(() => {
        setItems(data)
    }, [data])


    const { t } = useTranslation();


    const modalShow = (e, item) => {
        console.log('element::', e, 'item::', item);

        setId(item.id);
        setName(item.name);
        setDetail(item.detail);
        setImg(item.img);
        setAlt(item.alt);
        setList(item.list);

    }


    const setDeleteItem = (e, item) => setCategoryId(item.id);

    const comfirm = () => {
        deleteData(categoryId);
    }


    return (
        <div>
            <Menu title='All product' name={t('Product')} />
            <div className="modal fade" id="modalConfirm" tabIndex={-1} role="dialog">
                <div className="modal-dialog" role="document">
                    <div className="modal-content">
                        <div className="modal-header bg-danger text-white">
                            <h5 className="modal-title">Confirm</h5>
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">×</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            <p>{t('comfirn delete')}</p>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-danger" onClick={comfirm} data-dismiss="modal" ><FontAwesomeIcon icon={faTrashAlt} /> {t('delete')}</button>
                            <button type="button" className="btn btn-secondary" data-dismiss="modal"><FontAwesomeIcon icon={faPowerOff} /> {t('cancel')}</button>
                        </div>
                    </div>
                </div>
            </div>
            <div className="card shadow mb-4">
                <div className="card-header py-3">
                    <h6 className="m-0 font-weight-bold text-primary">{t('products')}</h6>
                </div>
                <div className="card-body">
                    <div className="table-responsive">
                        <table className="table table-bordered" id="dataTable" width="100%" cellSpacing={0}>
                            <thead>
                                <tr className=" font-12">
                                    <th>{t('Images')}</th>
                                    <th>{t('Name')}</th>
                                    <th style={{ minWidth: 90 }}>{t('Detail')}</th>
                                    <th>{t('alt')}</th>
                                    <th>{t('List')}</th>
                                    <th className="text-right">{t('Action')}</th>
                                </tr>
                            </thead>

                            <tbody>
                                {
                                    items?.map((item, key) =>
                                        <tr key={item.id}>
                                            <td  >

                                                <img
                                                    src={`${config.api}/images/category/${(item.img)?.split(".")[0]}/${(item.img)?.split(".")[1]}/small`}
                                                    className="img-fluid p-1" width="60px" name={item.img}
                                                    alt={item.alt || item.name}
                                                />

                                            </td>
                                            <td className='font-12'>{item.name}</td>
                                            <td className='font-11'  >{item.detail}</td>
                                            <td className='font-11'>{item.alt}</td>
                                            <td className="font-11">{item.list}</td>
                                            <td className="text-right font-10">
                                                <div className="btn-group" role="group">
                                                    <button id="btnGroupDrop1" type="button" className="btn btn-default dropdown-toggle btn-sm" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                        Action
                                                    </button>
                                                    <div className="dropdown-menu" aria-labelledby="btnGroupDrop1">
                                                        <button className="dropdown-item" onClick={(e) => modalShow(e, item)} data-toggle="modal" data-target="#addModalNew"><FontAwesomeIcon icon={faEdit} /> {t('Edit')} </button>
                                                        <button className="dropdown-item" onClick={(e) => setDeleteItem(e, item)} data-toggle="modal" data-target="#modalConfirm"><FontAwesomeIcon icon={faPowerOff} /> {t('delete')} </button>
                                                    </div>
                                                </div>
                                            </td>
                                        </tr>
                                    )
                                }
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>

        </div>
    )
}

export default Table;


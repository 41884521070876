import $ from 'jquery';
import React, { useEffect, useState } from 'react';
import { Buffer } from 'buffer';
import Category from '../api/category';
import Template from '../component/template';
import Table from '../component/table-category';
import ModalNew from '../component/modalNew';
import { t } from 'i18next';
const { client } = require('../api/auth').default;

const Categories = () => {
    const TableName = 'category';
    const Model = Category;

    const [category, setCategory] = useState(null);

    const [id, setId] = useState('');
    const [name, setName] = useState('');
    const [detail, setDetail] = useState('');
    const [img, setImg] = useState('');
    const [alt, setAlt] = useState('');
    const [list, setList] = useState([]);

    const [files, setFiles] = useState("");
    const [ext, setExt] = useState("");

    const fetchData = async () => {
        try {
            let cate = await Model.getDataList();
            setCategory(cate);
        } catch (e) {
            console.error(`error get ${TableName}::`, e)
        }

    }

    useEffect(() => {

        localStorage.removeItem(`${TableName}-model-list`)
 
        fetchData().catch(console.error);
        
    }, [])


    const deleteData = (id) => {

        let data = category.filter((e) => e.id !== id);
        setCategory(data);
        Model.remove(id)

        console.log('new data::', category)
    }

    const submitForm = async (e) => {
        e.preventDefault();
        try {

            if (!name) {
                throw new Error('please input name')
            }
            let body = {
                name: name,
                detail: detail,
                img: files,
                img_ext: ext,
                alt: alt,
                list: list
            }


            if (id !== '') {
                console.log('sent update', body)
                let response = await client.patch(`/${TableName}/${id}`, body);
                setId('');
                console.log(id !== '' ? true : false);
                if (response.data.status === 2) {
                    console.log('items update::', TableName)
                    $('body').find('button.close').click();
                    fetchData().catch(console.error);
                }

            } else {
                let response = await client.post(`/${TableName}`, body);

                console.log('response::', response.data.data);
                if (response.data.status === 2) {
                    // let newData = category;
                    // newData.push(response.data.data)
                    // setCategory(newData)
                    fetchData().catch(console.error);

                    console.log('items::', category)
                    $('body').find('button.close').click();
                }
            }


        } catch (e) {
            console.error('Error:: ', e);
            warning('Error:: ' + e.message);
            $('body').find(`input.${TableName}-name`).focus();
        }
    };


    const warning = (message) => {
        alert(message);
    }


    const handleChange = e => {
        const fileReader = new FileReader();
        fileReader.readAsDataURL(e.target.files[0], "UTF-8");
        setExt(e.target.files[0].name.split('.').pop());

        fileReader.onload = e => {
            setFiles(Buffer.from(e.target.result, 'base64'));
        };
    };


    return (
        <Template >
            <ModalNew submitForm={submitForm} ModalName={t('Category')}>
                <Category.Add
                    TableName={TableName}
                    handleChange={handleChange}
                    id={id}
                    name={name}
                    detail={detail}
                    img={img}
                    alt={alt}
                    list={list}
                    

                    setName={setName}
                    setDetail={setDetail}
                    setImg={setImg}
                    setAlt={setAlt}
                    setList={setList}
                />
            </ModalNew>

            <Table

                data={category}
                deleteData={deleteData}

                setId={setId}
                setName={setName}
                setDetail={setDetail}
                setImg={setImg}
                setAlt={setAlt}
                setList={setList}
            />
        </Template>
    );
}

export default Categories;

import { t } from 'i18next';
import React, { useRef, useState } from 'react';
import { Buffer } from 'buffer';
import _global from '../../api/global';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft, faEllipsisVertical, faPaperclip, faTimes } from '@fortawesome/free-solid-svg-icons';
import '../../fileUpload.css';



/**
 * 
 * @param {form} requred
 * @param {viewData} requred
 *  
 */
const FormUpload = ({ controller }) => {
    const hiddenFileInput = useRef(null);
    const [openFile, setOpenFile] = useState(null);

    const viewFile = async (file) => {
        try {
            setOpenFile(file);
            const { data: response } = await _global.client.get("/v1/file", { params: { id: file.id } });
            console.warn('get file response::', response);
        } catch (e) {
            console.error('error view file::', e);
        }
    }

    const attachClick = () => {
        hiddenFileInput.current.click();
    };

    const removeFile = async (file) => {
        console.log('file remove::', file);
        let newFiles = controller.form?.attach || [];


        // api remove file
        // try {
        //     const { data: response } = await _global.client.delete("/v1/file", { params: { id: file.id } });
        //     console.warn('remove response::', response);
        //     if (response.status === 2) {
        //         newFiles = newFiles.filter(el => el.id !== file.id);
        //         controller.setForm({ ...controller.form, attach: newFiles })
        //     }
        // } catch (e) {
        //     console.error('error remove file::', e);
        // }

        newFiles = newFiles.filter(el => el.id !== file.id);
        controller.setForm({ ...controller.form, attach: newFiles })

    }

    const handleFileSelect = async (e) => {

        let newFiles = controller.form?.attach || [];

        for (let file of e.target.files) {
            const fileReader = new FileReader();
            fileReader.readAsDataURL(file, "UTF-8");
            let name = file.name;
            let extension = file.name.split('.').pop();
            //setFileExt(extension);
            ///setFileName(name)

            fileReader.onload = async (e) => {
                let rawData = Buffer.from(e.target.result, 'base64');
                //setFiles(rawData);


                try {
                    let body = {
                        title: name,
                        files: rawData,
                        ext: extension,
                        setting: JSON.stringify({
                            name: name,
                            ext: extension
                        })
                    }
                    const { data: response } = await _global.client.post("/v1/file", body);
                    //console.log('response::', response);
                    if (response.status === 2) {
                        newFiles.push(response.data)
                        controller.setForm({ ...controller.form, attach: newFiles })
                    }
                } catch (error) {
                    console.log(error)
                }
            };
        }
    }





    return (
        <div>
            <div className={`btn btn-sm text-primary ${controller.viewData ? 'd-none' : ' '} `} onClick={attachClick}><FontAwesomeIcon icon={faPaperclip} /> {t('attach')}</div>
            <input className='d-none' type="file" ref={hiddenFileInput} onChange={handleFileSelect} multiple />
            <div className={`row`}>
                {
                    controller.form?.attach && controller.form?.attach.map((el, index) => (
                        <div key={index + 1} className={`col-12 font-12`}>
                            <div className='border m-1 p-2 d-flex justify-content-between bg-red-light' >
                                <div className="text-info cursor-pointer" onClick={() => viewFile(el)}>
                                    {el.title}
                                </div>
                                <div className=" ">
                                    {el.setting && _global.formatBytes(JSON.parse(el.setting).size)}
                                </div>
                                <div className={controller.viewData ? 'd-none' : 'd-block'}>
                                    <div className='cursor-pointer text-danger' onClick={(e) => removeFile(el)}><FontAwesomeIcon icon={faTimes} /></div>
                                </div>
                            </div>
                        </div>
                    ))
                }
            </div>

            <div className={`modal-file ${openFile ? 'd-block' : 'd-none'}`}>
                <div className='d-flex justify-content-between modal-file-head'>
                    <div className=' ' style={{ paddingLeft: 20, paddingTop: 10, fontSize: 20, color: '#fff' }}>
                        <div className='btn-modal-file' onClick={() => setOpenFile(false)}>
                            <FontAwesomeIcon icon={faArrowLeft} />
                        </div>
                    </div>
                    <div className=' ' style={{ paddingTop: 10, paddingRight: 20, color: '#fff' }}>
                        <div className='btn-modal-file'>
                            <FontAwesomeIcon icon={faEllipsisVertical} />
                        </div>
                    </div>
                </div>
                <div className='modal-file-body' >

                    {
                        openFile?.name && <iframe
                            src={`${_global.config.api}/uploads/${openFile?.name}`}
                            frameBorder="0"
                            height={'100%'}
                            width={'100%'}
                        />
                    }
                    <pre>
                        {
                            JSON.stringify(openFile, null, 2)
                        }
                    </pre>

                </div>
            </div>

        </div>
    )
};

const FileUpload = {
    FormUpload
};

export default FileUpload;
import React from 'react';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faFloppyDisk, faPowerOff
} from '@fortawesome/free-solid-svg-icons';

const ModalNew = (props) => {
    const { screen, name } = props;
    const { t } = useTranslation();


    return (
        <div className={`modal fade ${screen ?? 'modal-xl'}`} id={`addModalImage-${name}`} tabIndex={-1} role="dialog" aria-hidden="true">
            <div className="modal-dialog modal-xl" role="document">
                <div className="modal-content">
                    <div className="  ">
                         <button className="close mr-3 mt-1" type="button" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">×</span>
                        </button>
                    </div>
                    {props.children}
                </div>
            </div>
        </div>
    );
}

export default ModalNew;

import React, { useEffect, useState } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Menu from '../../component/menu';
import _global from '../../api/global';
import moment from "moment";
import UserApi from '../../api/user';
import {
    faTrashAlt,
    faPowerOff,
    faEdit,
    faCopy,
    faSearch,
    faPlusCircle,
    faEye,
    faEyeDropper
} from '@fortawesome/free-solid-svg-icons';
import DataTable from 'react-data-table-component';
import Checkbox from '@material-ui/core/Checkbox';
import ArrowDownward from '@material-ui/icons/ArrowDownward';
import ProductApi from '../../api/products';

function Table({
    data,
    deleteProduct,
    setCode,
    setName,
    setId,
    setDetail,
    setPrice,
    setPrices,
    setUnit,
    categoryId,
    setCategoryId,
    setStatus,
    setType,
    setDescription,
    setLocation,
    setTempFile,
    setFileName,
    stock,
    setStock,
    cloneItem,
    setItemSource,
    searchProduct,
    noDataComponent,
    setNoDataComponent,
    t,
    controller
}) {

    const navigate = useNavigate();
    const [productId, setProductId] = useState('');
    const [items, setItems] = useState([]);
    const [locations, setLocations] = useState([]);
    const [dataClone, setDataClone] = useState({});

    let date = new Date();
    let fromDate = new Date(date.getFullYear(), date.getMonth(), date.getDate() - 1);
    let toDate = new Date(date.getFullYear(), date.getMonth(), date.getDate() + 1);


    useEffect(() => {
        setItems(data);
        setLocations(_global.locations);

    }, [data])

    // const linkToDetail = (event, item, modify = false) => {
    //     console.log("ID ", event, item);
    //     navigate(`/assets/views/${item.id}/${item.name}?modify=${modify}`, { replace: true, modify: modify });
    // };



    const modalShow = (e, item) => {
        //console.log('modal.show =>', item)
        try {
            setName(item.name);
            setDetail(item.detail);
            setId(item.id || null);
            setPrice(item.price);
            setCategoryId(item.category || categoryId);
            setStatus(item.status);
            setType(item.item_type);
            setDescription(item.description);
            setLocation(item.location || 1);
            setTempFile(`${_global.config.api}/images/items/${(item.img)?.split(".")[0]}/${(item.img)?.split(".")[1] || 'jpg'}/small`);
            setFileName(item.img);
            setPrices(item.prices ? JSON.parse(item.prices) : { [t('hours')]: 1 });
            setUnit(item.unit ? JSON.parse(item.unit) : { 'unit': t('unit') });
            setItemSource(item.item_source || 1);
            setCode(item.code);
            setStock(item.stock);
            controller.setForm(item);
        } catch (e) {
            console.error('error set data', e);
        }
    }


    const setDeleteItem = (e, item) => setProductId(item.id);
    const setDataForClone = (e, item) => setDataClone(item);

    const comfirm = () => {
        deleteProduct(productId);
    }

    const getLocation = (id) => {
        return locations?.filter((el) => el.id === id)[0]?.name
    }

    const confirmCloneItem = () => {
        cloneItem(dataClone);
    }



    /**
     * Data Table 
     * https://react-data-table-component.netlify.app/?path=/docs/getting-started-intro--page
     * 
     * */

    const columns = [
        {
            name: t('product name'),
            selector: row => row.name,
            sortable: true,
            id: "name",
            cell: (item) => <div>
                <div className='mt-3'>
                    {
                        item.images && item.images.length > 0 ?
                            <img
                                src={`${_global.config.api}/uploads/${(item.images[0])}`}
                                className="img-fluid p-1" width="60px" name={item.img}
                                alt={item.alt || item.name} id={item.id}
                            />
                            :
                            <img
                                src={`${_global.config.api}/images/items/${(item.img)?.split(".")[0]}/${(item.img)?.split(".")[1] || 'jpg'}/small`}
                                className="img-fluid p-1" width="60px" name={item.img}
                                alt={item.alt || item.name} id={item.id}
                            />
                        // item.images && item.images.length > 0 ?
                        //     item?.images.map((el, index) =>
                        //         <div key={index} className='col-3 mb-3' style={{ position: 'relative' }}>
                        //             <div style={{ maxWidth: '100%' }}
                        //                 className='border border-2 shadow-5'
                        //             >
                        //                 <a href={`/file/${el}`} target="_bank" className='text-decoration-none' id={el}>
                        //                     <img
                        //                         src={`${_global.config.api}/uploads/${(el)}`}
                        //                         className="img-fluid " name={el}
                        //                         alt={el}
                        //                         style={{ maxHeight: '100%' }}
                        //                     />
                        //                 </a>
                        //             </div>
                        //         </div>
                        //     )
                        //     :
                        //     <img
                        //         src={`${_global.config.api}/images/items/${(item.img)?.split(".")[0]}/${(item.img)?.split(".")[1] || 'jpg'}/small`}
                        //         className="img-fluid p-1" width="60px" name={item.img}
                        //         alt={item.alt || item.name} id={item.id}
                        //     />
                    }

                </div>
                <div className="d-inline-block align-middle mb-0">
                    <div className="d-inline-block align-middle mb-0 product-name">
                        {item.name}
                    </div>
                    <br />
                    <span className="text-muted font-10">{item.description}</span>
                    <br />
                    <span className="text-muted font-10">{item.detail}</span>
                    <span className="w-100 font-12 pl-2"
                        style={{
                            position: 'absolute',
                            top: 0,
                            left: 0,
                            background: "rgb(0 0 0 / 21%)",
                            color: '#FFF'
                        }}
                    >{item.code}</span>
                </div>
            </div>,

        },
        {
            name: t('category'),
            selector: row => row.category_name,
            sortable: true,
            maxWidth: '180px'
        },
        {
            name: t('item source'),
            selector: row => <div> {_global.ItemSource(row.item_source).text}</div>,
            sortable: true,
        },
        {
            name: t('location'),
            selector: row => row.location,
            sortable: true,
            cell: (item) => <div> {getLocation(item.location)}</div>,
            maxWidth: '50px'
        },
        // {
        //     name: t('stock'),
        //     selector: row => row.stock,
        //     sortable: true,
        //     maxWidth: '50px'
        // },
        // {
        //     name: t('remaining'),
        //     selector: row => row.remaining,
        //     sortable: true,
        //     maxWidth: '50px'
        // },
        {
            name: t('remaining'),
            title: t('remaining'),
            selector: row => row.price,
            sortable: true,
            cell: (item) => <div className='w-100'>
                {/* <div className='d-flex justify-content-between'><div>{t('stock')}: </div>{_global.NumberFormat(item.stock, 0)}</div> */}
                <div
                    className='d-flex justify-content-between cursor-pointer text-primary'
                    onClick={() => controller.modal.openModal(fromDate, toDate, item, 'stock')}
                    data-toggle="modal"
                    data-target="#stockTxnModal"
                >
                    <div className='' >
                        {t('stock')}:
                    </div>
                    {_global.NumberFormat(item.stock, 0)}
                </div>
                <div
                    className='d-flex justify-content-between cursor-pointer text-primary'
                    onClick={() => controller.modal.openModal(fromDate, toDate, item, 'remaining')}
                    data-toggle="modal"
                    data-target="#stockTxnModal"
                >
                    <div className='' >
                        {t('remaining')}:
                    </div>
                    {_global.NumberFormat(item.remaining, 0)}
                </div>
            </div>,
            maxWidth: '150px'
        },
        // {
        //     name: t('price'),
        //     title: t('price'),
        //     selector: row => row.price,
        //     sortable: true,
        //     cell: (item) => <div className='w-100 text-right'>{_global.NumberFormat(item.price)}</div>,
        //     maxWidth: '150px'
        // },

        {
            name: t('status'),
            selector: row => row.status,
            sortable: true,
            cell: (item) => <div className={`gradient-buttonsfont-10 ${item.status === 'Active' ? 'text-success' : 'text-danger'} `}>{item.status}</div>,
            maxWidth: '50px'
        },
        {
            cell: (item) => <div className='gradient-buttons w-100 text-right'>
                {/* <button className="btn btn-info btn-xs" onClick={(e) => setDataForClone(e, item)} data-toggle="modal" data-target="#modalConfirmClone" ><FontAwesomeIcon icon={faCopy} /> {t('clone item')} </button> */}
                {/* <button className="btn btn-info btn-xs ml-1" onClick={(e) => linkToDetail(e, item)}><FontAwesomeIcon icon={faEyeDropper} /> {t('view')}</button> */}
                {/* <button className="btn btn-warning btn-xs ml-1" onClick={(e) => linkToDetail(e, item, true)} data-toggle="modal" data-target="#addModalNew-Assets"><FontAwesomeIcon icon={faEdit} /> {t('edit')} </button> */}

                <Link className="btn btn-info btn-xs ml-1" to={`/assets/views/${item.id}/${item.name}`}>
                    <FontAwesomeIcon icon={faEyeDropper} />{t('view')}
                </Link>
                <Link className="btn btn-warning btn-xs ml-1" to={`/assets/views/${item.id}/${item.name}?modify=true`}>
                    <FontAwesomeIcon icon={faEdit} /> {t('edit')}
                </Link>
                <button className="btn btn-danger btn-xs ml-1" onClick={(e) => setDeleteItem(e, item)} data-toggle="modal" data-target="#modalConfirm-Delete"><FontAwesomeIcon icon={faTrashAlt} /> {t('delete')} </button>
            </div >,
            name: t('Action'),
            selector: row => row.id,
            width: '240px',
        },

    ];

    const ExpandedComponent = ({ data: {
        id,
        code,
        name,
        img,
        alt,
        description,
        detail,
        prices,
        item_type,
        item_source,
        location,
        category,
        category_name,
        create_by_name,
        create_when,
        update_when,
        update_by_name,
        images
    } }) =>
        <div className='w-100' style={{ borderLeft: "1px solid #ccc", borderRight: "1px solid #ccc", backgroundColor: "#f5f5f5" }}>
            <div className="row">
                <div className="col-12 col-sm-4 p-3" style={{ position: 'relative' }}>
                    <div className='m-2' style={{ position: 'absolute', zIndex: 2 }}>{id}</div>
                    {
                        images && images.length > 0 ?
                            <img
                                src={`${_global.config.api}/uploads/${(images[0])}`}
                                className="img-fluid w-100 img-shadow" name={img}
                                alt={alt || name} id={id}
                            />
                            :
                            <img
                                src={`${_global.config.api}/images/items/${(img)?.split(".")[0]}/${(img)?.split(".")[1] || 'jpg'}/large`}
                                className="img-fluid w-100 img-shadow" name={img}
                                alt={alt || name}
                            />
                    }

                </div>
                <div className="col-12 col-sm-8 p-3">
                    <div className="row">

                        <div className="col-12">
                            <div className="d-inline-block align-middle mb-0">
                                <div className="  product-code">
                                    <div style={{ borderBottom: "1px solid #ccc", width: "100%" }}>{t('CODE')} : <b>{code}</b></div>
                                </div>
                                <div className="d-inline-block align-middle mb-0 product-name mt-2">
                                    <div style={{ borderBottom: "3px double #ccc", width: "100%" }}>{name}</div>
                                </div>
                                <br />
                                <span className="text-muted font-10">{description}</span>
                                <br />
                                <span className="text-muted font-10">{detail}</span>
                            </div>
                        </div>

                        <div className="col-12">
                            <div className="row" style={{ marginBottom: -5 }}>
                                <label htmlFor="product-update_by" className="col-sm-4 col-form-label">{t('Item type')}</label>
                                <div className="col-sm-8">
                                    <input type="text"
                                        disabled={true}
                                        className="form-control-plaintext"
                                        id="product-create_by_name"
                                        defaultValue={_global.ItemType(item_type).text}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="col-12">
                            <div className="row" style={{ marginBottom: -5 }}>
                                <label htmlFor="product-update_by" className="col-sm-4 col-form-label">{t('item group')}</label>
                                <div className="col-sm-8">
                                    <input type="text"
                                        disabled={true}
                                        className="form-control-plaintext"
                                        id="product-create_by_name"
                                        defaultValue={category_name}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="col-12">
                            <div className="row" style={{ marginBottom: -5 }}>
                                <label htmlFor="product-update_by" className="col-sm-4 col-form-label">{t('item source')}</label>
                                <div className="col-sm-8">
                                    <input type="text"
                                        disabled={true}
                                        className="form-control-plaintext"
                                        id="product-create_by_name"
                                        defaultValue={_global.ItemSource(item_source).text}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="col-12">
                            <div className="row" style={{ marginBottom: -5 }}>
                                <label htmlFor="product-update_by" className="col-sm-4 col-form-label">{t('location')}</label>
                                <div className="col-sm-8">
                                    <input type="text"
                                        disabled={true}
                                        className="form-control-plaintext"
                                        id="product-create_by_name"
                                        defaultValue={getLocation(location)}
                                    />
                                </div>
                            </div>
                        </div>

                        <div className="col-12">
                            <hr />
                            <div className="d-inline-block align-middle mb-0  ">
                                <div className="d-inline-block align-middle mb-0 product-name">
                                    <div style={{ borderBottom: "3px double #ccc", width: "100%" }}>{t('price')}</div>
                                </div>
                            </div>
                            <div className=" ">
                                {
                                    JSON.parse(prices) && Object.keys(JSON.parse(prices))?.map((keyName, index) => (
                                        <div key={index} >
                                            <div className="form-group row" style={{ marginBottom: -10 }} >
                                                <label htmlFor="product-prices" className="col-sm-3 col-form-label">{keyName}</label>
                                                <div className="col-sm-2">
                                                    <input type="text" disabled={true} className="form-control-plaintext text-right" id="product-prices" defaultValue={_global.NumberFormat(JSON.parse(prices)[keyName], 0) || 0} />
                                                </div>
                                                <div className="col-sm-7">
                                                    <input type="text" className="form-control-plaintext" disabled={true} defaultValue={t('baht')} />
                                                </div>
                                            </div>
                                        </div>
                                    ))
                                }
                            </div>
                        </div>
                        <div className="col-12">
                            <div className="row">
                                <div className="col">
                                    <hr />
                                </div>
                            </div>
                            <div className="row">
                                <div className="col">
                                    <div className="row" style={{ marginBottom: -5 }}>
                                        <label htmlFor="product-update_by" className="col-sm-4 col-form-label">{t('create by')}</label>
                                        <div className="col-sm-8">
                                            <input type="text"
                                                disabled={true}
                                                className="form-control-plaintext"
                                                id="product-create_by_name"
                                                defaultValue={create_by_name}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="col">
                                    <div className="row" style={{ marginBottom: -5 }}>
                                        <label htmlFor="product-create_when" className="col-sm-4 col-form-label">{t('create date')}</label>
                                        <div className="col-sm-8">
                                            <input type="text" disabled={true}
                                                className="form-control-plaintext"
                                                id="product-create_when"
                                                defaultValue={create_when && moment(create_when).format('YYYY-MM-DD HH:mm:ss')}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col">
                                    <div className="row" >

                                        <label htmlFor="product-update_by" className="col-sm-4 col-form-label">{t('update by')}</label>
                                        <div className="col-sm-8">
                                            <input type="text"
                                                disabled={true}
                                                className="form-control-plaintext"
                                                id="product-update_by"
                                                defaultValue={update_by_name}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="col">

                                    <div className="row" >
                                        <label htmlFor="product-update_when" className="col-sm-4 col-form-label">{t('update date')}</label>
                                        <div className="col-sm-8">
                                            <input type="text"
                                                disabled={true}
                                                className="form-control-plaintext"
                                                id="product-update_when"
                                                defaultValue={update_when && moment(update_when).format('YYYY-MM-DD HH:mm:ss')}
                                            />
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </div>
        ;

    function DataTableBase(props) {
        return (
            <DataTable
                noDataComponent={noDataComponent}
                pagination
                selectableRowsComponent={Checkbox}
                paginationPerPage={50}
                paginationRowsPerPageOptions={[10, 20, 50, 100, 200, 300]}
                selectableRowsComponentProps={{ indeterminate: isIndeterminate => isIndeterminate }}
                sortIcon={<ArrowDownward />}
                dense
                columns={columns}
                data={items || []}
                expandableRows
                //expandableRowExpanded={row => true}
                //expandableRowDisabled={row => false}
                expandableRowsComponent={ExpandedComponent}
                sortFunction={_global.customSort}
                defaultSortFieldId="name"
                customStyles={{
                    header: {
                        style: {
                            minHeight: '56px',
                        },
                    },
                    headCells: {
                        style: {
                            '&:not(:last-of-type)': {
                                fontWeight: 'bold',
                                borderTopStyle: 'solid',
                                borderTopWidth: '1px',
                                borderTopColor: '#ccc',
                                borderLeftStyle: 'solid',
                                borderLeftWidth: '1px',
                                borderLeftColor: '#ccc',
                                borderTop: "5px solid black",
                                borderBottom: "1px double #ccc"
                            },
                            "&:last-child": {
                                borderTopStyle: 'solid',
                                borderTopWidth: '1px',
                                borderTopColor: '#ccc',
                                borderRightStyle: 'solid',
                                borderRightWidth: '1px',
                                borderRightColor: '#ccc',
                                borderLeftStyle: 'solid',
                                borderLeftWidth: '1px',
                                borderLeftColor: '#ccc',
                                borderTop: "5px solid black",
                                borderBottom: "1px double #ccc"
                            }
                        },
                    },
                    rows: {
                        style: {
                            borderStyle: 'solid',
                            borderWidth: '1px',
                            borderColor: '#ccc'
                        },
                    },
                    cells: {
                        style: {
                            '&:not(:last-of-type)': {
                                borderRightStyle: 'solid',
                                borderRightWidth: '1px',
                                borderRightColor: '#ccc'
                            },
                        },
                    },
                }}
                highlightOnHover
                pointerOnHoverF
                {...props}
            />
        );
    }

    // console.log('location', locations?.filter((el) => el.id === 1)[0].name)
    //console.log('items::', items);
    return (
        <div>
            <Menu title={t('All product')} name={t('Product')} setPrices={setPrices} modalShow={modalShow} />
            <div className="modal fade" id="modalConfirm-Delete" tabIndex={-1} role="dialog">
                <div className="modal-dialog" role="document">
                    <div className="modal-content">
                        <div className="modal-header bg-danger text-white">
                            <h5 className="modal-title">{t('Confirm')}</h5>
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">×</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            <p>{t('comfirn delete')} ?</p>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-danger" onClick={comfirm} data-dismiss="modal" ><FontAwesomeIcon icon={faTrashAlt} /> {t('delete')}</button>
                            <button type="button" className="btn btn-secondary" data-dismiss="modal"><FontAwesomeIcon icon={faPowerOff} /> {t('cancel')}</button>
                        </div>
                    </div>
                </div>
            </div>
            <div className="modal fade" id="modalConfirmClone" tabIndex={-1} role="dialog">
                <div className="modal-dialog" role="document">
                    <div className="modal-content">
                        <div className="modal-header bg-info text-white">
                            <h5 className="modal-title">{t('Confirm')}</h5>
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">×</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            <p>{t('comfirn clone')} ?</p>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-primary" onClick={confirmCloneItem} data-dismiss="modal" ><FontAwesomeIcon icon={faCopy} /> {t('Yes')}</button>
                            <button type="button" className="btn btn-secondary" data-dismiss="modal"><FontAwesomeIcon icon={faPowerOff} /> {t('cancel')}</button>
                        </div>
                    </div>
                </div>
            </div>

            <div className="card shadow mb-4">
                <div className="card-header py-3">
                    <div className="row">
                        <div className="col">
                            <h6 className="m-0 font-weight-bold text-primary">{t('Product')}</h6>
                        </div>

                    </div>
                </div>
                <div className="card-body">
                    <div className="">
                        <div className="row">
                            <div className="col-12 col-sm-8"></div>
                            <div className="col-12 col-sm-4  mb-3">
                                <div className="input-group mb-3">
                                    <input type="text" className="form-control" onChange={(el) => searchProduct(el.target.value)} />
                                    <div className="input-group-append">
                                        <span className="input-group-text" id="search-product" ><FontAwesomeIcon icon={faSearch} /> </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <DataTableBase />
                    </div>
                </div>
            </div>
            {/* <div className="card shadow mb-4">
                <div className="card-header py-3">
                    <h6 className="m-0 font-weight-bold text-primary">{t('products')}</h6>
                </div>
                <div className="card-body">
                    <div className="table-responsive">
                        <table className="table table-bordered" id="dataTable" width="100%" cellSpacing={0}>
                            <thead>
                                <tr>
                                    <th>{t('product name')}</th>
                                    <th>{t('Category')}</th>
                                    <th>{t('location')}</th>
                                    <th>{t('Stock')}</th>
                                    <th>{t('Price')}</th>
                                    <th>{t('Status')}</th>
                                    <th className="text-right">{t('Action')}</th>
                                </tr>
                            </thead>

                            <tbody>
                                {
                                    items?.map((item, key) =>
                                        <tr key={item.id}>
                                            <td width="30%">
                                                <img
                                                    src={`${config.api}/images/items/${(item.img)?.split(".")[0]}/${(item.img)?.split(".")[1] || 'jpg'}/small`}
                                                    className="img-fluid p-1" width="60px" name={item.img}
                                                    alt={item.alt || item.name}
                                                />
                                                <div className="d-inline-block align-middle mb-0">
                                                    <div className="d-inline-block align-middle mb-0 product-name">
                                                        {item.name}
                                                    </div>
                                                    <br />
                                                    <span className="text-muted font-10">{item.description}</span>
                                                    <br />
                                                    <span className="text-muted font-10">{item.detail}</span>
                                                </div>
                                            </td>
                                            <td className='font-12'>{item.category_name}</td>
                                            <td className='font-11'>{getLocation(item.location)}</td>
                                            <td className='text-right font-11'>{parseInt(item.stock)}</td>
                                            <td className='text-right font-11'>{item.price}</td>
                                            <td className={`font-10 ${item.status === 'Active' ? 'text-success' : 'text-danger'} `}>{item.status}</td>
                                            <td className="text-right font-10">
                                                <button className="btn btn-info btn-xs" onClick={(e) => setDataForClone(e, item)} data-toggle="modal" data-target="#modalConfirmClone" ><FontAwesomeIcon icon={faCopy} /> {t('clone item')} </button>
                                                <button className="btn btn-warning btn-xs ml-1" onClick={(e) => modalShow(e, item)} data-toggle="modal" data-target="#addModalNew"><FontAwesomeIcon icon={faEdit} /> {t('edit')} </button>
                                                <button className="btn btn-danger btn-xs ml-1" onClick={(e) => setDeleteItem(e, item)} data-toggle="modal" data-target="#modalConfirm"><FontAwesomeIcon icon={faPowerOff} /> {t('delete')} </button>
                                            </td>
                                        </tr>
                                    )
                                }
                            </tbody>
                        </table>
                    </div>
                </div>
            </div> */}

        </div>
    )
}

export default Table;


import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import SideBar from '../sidebar'
import Table from '../../pages/products/table';
import TopBar from '../top-bar';
import Dashboard from '../dashboard';
import Footer from './footer';
import Modal from '../modal';
import { useNavigate } from 'react-router-dom';



const Template = (props) => {
    const navigate = useNavigate();

    useEffect(() => {

        if (!localStorage.getItem('token')) {
            navigate('/login', { replace: false });
        }

    }, [])


    const { t } = useTranslation();


    return (
        <div>
            <div id="wrapper">
                <SideBar />
                <div id="content-wrapper" className="d-flex flex-column relative">
                    <div id="content">
                        <TopBar />
                        <div className="container-fluid">
                            {props.children}
                        </div>
                    </div>
                    <Footer props={props}/>
                </div>
            </div>
            <a className={`scroll-to-top rounded ${props.hideToTop ? 'd-none' : '' }`} href="#root">
                <i className="fas fa-angle-up" />
            </a>
            <Modal />
        </div>
    );
}

export default Template;

import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';


function Footer({ props }) {
    const { t } = useTranslation();

    useEffect(() => {

    }, [])

    return (
        <footer className={`sticky-footer bg-white ${props.hideFooter ? 'd-none' : ''}`}>
            <div className="container my-auto">
                <div className="copyright text-center my-auto">
                    <span>Copyright © thammasak.co.th 2022-2023</span>
                </div>
            </div>
        </footer>
    )
}

export default Footer;


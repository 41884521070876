import { useTranslation } from 'react-i18next';
import React from 'react';
import { faPlusCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

 

function Menu(props) {
    const { t } = useTranslation();
    const modalAdd = (e, item) => {
        console.log('element::', e, 'item::', item);
    }

    return (
        <div className='col'>
            <div className='row'>
                <div className='col'>
                    <h1 className="h3 mb-2 text-gray-800">{t(props.title)}</h1>
                </div>
                <div className='col text-right'>
                    <button type="button" className='btn btn-outline-success btn-sm' onClick={(e) => modalAdd(e, 'item')} data-toggle="modal" data-target="#addModalNew-Assets"><FontAwesomeIcon icon={faPlusCircle} /> {t('Add')}</button>
                </div>
            </div>            
        </div>
    )
}

export default Menu;
import React from 'react';
import { useTranslation } from 'react-i18next';
import Template from '../component/template';
import Dashboard from '../component/dashboard';
 
const Home = () => {
    const { t } = useTranslation();
    
    return (
        <Template>
            <Dashboard />
 
        </Template>
    );
}

export default Home;

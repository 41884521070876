import { t } from 'i18next';
import React, { useEffect, useState } from 'react';
import BootstrapSwitchButton from 'bootstrap-switch-button-react'


const axios = require('axios').default;
const config = require(`../config/${process.env.NODE_ENV}`).default;

let client = axios.create({
    baseURL: config.api,
});

client.interceptors.request.use(
    config => {
        if (localStorage.getItem('token')) {
            config.headers['Authorization'] = `Bearer ${localStorage.getItem('token')}`;
        } else {
            localStorage.removeItem('token')
        }
        return config;
    },
    error => {
        return Promise.reject(error);
    }
);

client.interceptors.response.use((response) => {
    return response;
}, (error) => {
    console.error('error.response.status::', error.response)
    if(error.response.status === 401){
        localStorage.removeItem('token')
    }
    return Promise.reject(error.message);
});



const getOrderList = async (from, to) => {
    try {
        const response = await client.get(`/v1/orders?from=${from}&to=${to}`);
        return response.data;
    } catch (error) {
        console.error('GET Order::',error);
    }
}

const getOrderLine = async (id) => {
    try {
        const response = await client.get(`/v1/orders/line/${id}`);
        return response.data;
    } catch (error) {
        console.error(error);
    }
}

const getOrderPdf = async (from, to) => {
    try {
        const response = await client.get(`/pdf/orders/1`);
        return response;
    } catch (error) {
        console.error(error);
    }
}

const getProduct = async (id) => {
    try {
        const response = await client.get(`/products/${id}`);
        return response.data;
    } catch (error) {
        console.error(error);
    }
}

const getProductByCat = async (cat) => {
    try {
        const response = await client.get(`/products/cat/${cat}`);
        return response.data;
    } catch (error) {
        console.error(error);
    }
}

const remove = async (id) => {
    try {
        return await client.delete(`/products/${id}`);
    } catch (e) {
        console.error('error::', e);
    }
}


const AddProduct = (props) => {
    const [category, setCategory] = useState([]);
    let {
        id,
        name,
        detail,
        price,
        categoryId,
        status,
        type,
        setName,
        setDetail,
        setPrice,
        setCategoryId,
        setStatus,
        handleChange,
        setType,
    } = props;

    useEffect(() => {
        let cat = localStorage.getItem('category') ? JSON.parse(localStorage.getItem('category')) : [];
        setCategory(cat);
    }, [])

    return <div>
        <div className="row">
            <input type="hidden" value={id} name="id" />
            <div className="col-6">
                <label htmlFor='product-name'>{t('Name')}:</label>
                <input type="text" value={name} className="form-control" id="product-name" name="name" placeholder={t("Product Name")} onChange={e => setName(e.target.value)} />
            </div>
            <div className="col-2">
                <label htmlFor='product-name'>{t('Item type')}:</label>
                <select className="form-control" id="product-name" name="item_type" value={type} onChange={e => setType(e.target.value)} >
                    <option value={0}>{t('Goods')}</option>
                    <option value={1}>{t('Semi Product')}</option>
                    <option value={2}>{t('Material')}</option>
                </select>
            </div>
            <div className="col">
                <label htmlFor='product-price'>{t('price')} {t('sale/rent')}:</label>
                <input type="text" value={price} className="form-control" id="product-price" name="price" placeholder={t("Detail")} onChange={e => setPrice(e.target.value)} />
            </div>
        </div>
        <div className="row mt-2">
            <div className="col-8">
                <label htmlFor='product-price'>{t('Detail')}:</label>
                <textarea className="form-control" value={detail} id="exampleFormControlTextarea1" rows="3" onChange={e => setDetail(e.target.value)} />
            </div>
            <div className="col">
                <label htmlFor='product-category'>{t('Category')}:</label>
                <select className="custom-select" id="product-category" name="category" value={categoryId} onChange={e => setCategoryId(e.target.value)}>
                    {
                        category?.map((el, index) => <option key={index} value={el.id}>{el.name}</option>)
                    }
                </select>
            </div>
        </div>
        <div className="row mt-2 justify-content-end">
            <div className="col-4">
                <label htmlFor='validatedCustomFile'>{t('Image')}:</label>
                <div className="custom-file">
                    <label className="custom-file-label" htmlFor="validatedCustomFile">Choose image...</label>
                    <input
                        type="file"
                        name="file"
                        className="custom-file-input"
                        id="validatedCustomFile"
                        onChange={handleChange}
                        accept=".jpg,.png"
                    />
                    <div className="invalid-feedback">Example invalid custom file feedback</div>
                </div>
            </div>
        </div>
        <div className="row mt-2 justify-content-end">
            <div className="col-4">
                <BootstrapSwitchButton
                    checked={status === 'Active' ? true : false}
                    onlabel='Active'
                    offlabel='Disable'
                    onChange={(checked) => { setStatus(checked ? 'Active' : 'Disabled') }}
                    size="sm"
                    width={100}
                    onstyle="success"
                />
            </div>
        </div>
    </div>
}



const OrdersApi = {
    getOrderPdf,
    getProduct,
    getOrderList,
    getProductByCat,
    AddProduct,
    getOrderLine,
    remove,
    client
};

export default OrdersApi;
import React, { Suspense } from "react";
import { createRoot } from 'react-dom/client';
import './sb-admin-2.css';
import './index.css';
import './loading.css';
import './popup.css';
import './modal.css';
import App from './App';
import './i18n';
import loadingMakup from "./component/loading";

 
// append app to dom
const root = createRoot(document.getElementById('root'));
root.render(
  <Suspense fallback={loadingMakup} >
    <React.StrictMode>
      <App />
    </React.StrictMode>
  </Suspense>
); 
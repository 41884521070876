import { t } from 'i18next';
import React, { useEffect, useState } from 'react';
import BootstrapSwitchButton from 'bootstrap-switch-button-react'
import _global from './global';
import { Buffer } from 'buffer';
import _ from 'lodash';
import { Popup } from '../component/pupup';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCancel, faPlusCircle } from '@fortawesome/free-solid-svg-icons';
import BaseUnit from '../component/common/baseUnit';

const { client } = require('./auth').default;

const search = async (searchText) => {
    try {
        let products = [];
        const response = await client.get(`/products`, {
            params: {
                name: searchText
            }
        });
        products = await _global.mapCategory(response.data);
        products = products.filter(item => item.id && item.id > 0);

        return products
    } catch (error) {
        console.error(error);
    }
}
const getProductList = async (searchText) => {
    try {
        //let products = localStorage.getItem('products') ? JSON.parse(localStorage.getItem('products')) : [];
        // ---> disable cache
        let products = [];

        const response = await client.get(`/products`, {
            params: {
                name: searchText
            }
        });
        products = await _global.mapCategory(response.data);
        products = products.filter(item => item.id && item.id > 0);
        localStorage.setItem('products', JSON.stringify(products));

        products = products.map((obj) => {
            return {
                ...obj,
                images: obj.images ? obj.images.split(",") : []
            }
        })

        return products
    } catch (error) {
        console.error(error);
    }
}

const getProduct = async (id) => {
    try {
        _global.clearCached();
        const { data } = await client.get(`/products/${id}`);
        //console.warn('<<::', data);
        if(Object.entries(data).length > 0){
            data.images = data.images ? data.images.split(",") : [];
            //console.warn(typeof data.images);
        } 
        return data;
    } catch (error) {
        console.error(error);
    }
}

const getSummary = async (from, to, id) => {
    try {
        _global.clearCached();
        const response = await client.get(`/v1/inventory`, {
            params: {
                from: from,
                to: to,
                item: id
            }
        });
        //console.log('inventory response+----------------::', response);
        return response.data;
    } catch (error) {
        console.error(error);
    }
}

const getProductByCat = async (cat) => {
    try {
        const response = await client.get(`/products/cat/${cat}`);
        return response.data;
    } catch (error) {
        console.error(error);
    }
}

const remove = async (id) => {
    try {
        _global.clearCached();
        return await client.delete(`/products/${id}`);
    } catch (e) {
        console.error('error::', e);
    }
}


const AddProduct = ({
    id,
    code,
    name,
    detail,
    price,
    prices,
    categoryId,
    status,
    type,
    location,
    setCode,
    setName,
    setDetail,
    setPrice,
    setPrices,
    setCategoryId,
    setStatus,
    handleChange,
    setType,
    setLocation,
    description,
    setDescription,
    fileName,
    tempFile,
    unit,
    setUnit,
    setItemSource,
    itemSource,
    stock,
    setStock,
    controller
}) => {
    const [category, setCategory] = useState([]);
    const [closePopup, setClosePopup] = useState(true);
    const [closePopupUnit, setClosePopupUnit] = useState(true);

    const [units, setUnits] = useState({ [t('hours')]: 1 });
    const [unitOfmeasure, setUnitOfmeasure] = useState({ 'unit': t('unit') });

    const [priceList, setPriceList] = useState({});
    const [unitList, setUnitList] = useState();

    useEffect(() => {
        /**Fixed get category fail (undefined) */
        if (localStorage.getItem('category') === 'undefined') localStorage.removeItem('category');

        let cat = localStorage.getItem('category') ? JSON.parse(localStorage.getItem('category')) : [];
        setCategory(cat);

        /**
         * Remove comma to number
         */
        let newData = {};
        prices && Object.keys(prices).forEach(el => {
            newData[el] = prices[el].toString().replace(/,/g, '');
        });

        setPriceList(newData);
        setUnitList(unit || { 'unit': t('unit') });
    }, [prices, unit])

    const addUnit = () => {
        let newPrices = { ...prices, ...{ [units.name]: units.amount } };
        console.log('prices::', newPrices);
        setPriceList(newPrices);
        setPrices(newPrices);
        setClosePopup(true);
    }

    const rmUnit = (kyeName) => {
        let newPrice = _.clone(priceList);
        delete newPrice[kyeName];
        console.log('newPrice', newPrice)
        setPriceList(newPrice);
        setPrices(newPrice);
    }

    const addUnitOfMeasure = () => {
        let newUnits = { ...unit, ...{ [unitOfmeasure.name]: unitOfmeasure.name } };
        console.log('prices::', newUnits);
        setUnitList(newUnits);
        setUnit(newUnits);
        setClosePopupUnit(true);
    }

    const rmUnitOfMeasure = (kyeName) => {
        let newUnits = _.clone(unitList);
        delete newUnits[kyeName];
        console.log('newUnits', newUnits)
        setUnitList(newUnits);
        setUnit(newUnits);
    }

    //console.log('location::', location);
    //console.log('item_source::', itemSource);
    //console.log('id::', id);
    //console.log('category::', categoryId);
    return <div>
        <Popup name={'add-unit-of-measure'} className={closePopupUnit && 'hide'}>
            <div style={{ paddingTop: '2%', paddingLeft: '20%', paddingRight: '20%' }}>
                <div className="row">
                    <div className="col-6 text-white">
                        {t('unit')}
                    </div>
                    <div className="col-6">
                        <div className='text-right mb-3'>
                            <button className='btn btn-secondary btn-sm' onClick={() => setClosePopupUnit(true)}>
                                x
                            </button>
                        </div>
                    </div>
                </div>

                <div className="input-group mb-3" >

                    <div className="input-group-prepend">
                        <span className="input-group-text">{t(`unit`)}</span>
                    </div>
                    <input type="text"
                        className="form-control"
                        value={unitOfmeasure?.name || ''}
                        onChange={(e) => setUnitOfmeasure({ ...unitOfmeasure, name: e.target.value })}
                    />
                    <div className="input-group-append">
                        <button className="input-group-text btn bg-success" onClick={() => addUnitOfMeasure()}>+</button>
                    </div>
                </div>
                <div className='text-right'>
                    <button className='btn btn-success btn-sm' onClick={() => addUnitOfMeasure()}>
                        <FontAwesomeIcon icon={faPlusCircle} className="mr-2" />
                        {t('add')}
                    </button>
                </div>
            </div>
        </Popup>
        <Popup name={'add-unit'} className={closePopup && 'hide'}>
            <div className="row">
                <div className="col-6 text-white">
                    {t('price')}
                </div>
                <div className="col-6">
                    <div className='text-right mb-3'>
                        <button className='btn btn-secondary btn-sm' onClick={() => setClosePopup(true)}>
                            x
                        </button>
                    </div>
                </div>
            </div>

            <div className="input-group mb-3" >
                <div className="input-group-prepend">
                    <span className="input-group-text bg-warning">{t('unit')}</span>
                </div>
                <input type="text"
                    className="form-control "
                    defaultValue={units?.name || ''}
                    onChange={(e) => setUnits({ ...units, name: e.target.value })}
                />
                <div className="input-group-prepend">
                    <span className="input-group-text">{`=`}</span>
                </div>
                <input type="text"
                    className="form-control text-right"
                    defaultValue={units?.amount || 1}
                    onChange={(e) => setUnits({ ...units, amount: e.target.value })}
                    onKeyPress={(event) => _global.numberOnly(event)}
                />
                <div className="input-group-prepend">
                    <span className="input-group-text">{t('baht')}</span>
                </div>
                <div className="input-group-append">
                    <button className="input-group-text btn bg-success" onClick={() => addUnit()}>+</button>
                </div>
            </div>
            <div className='text-right'>
                <button className='btn btn-success btn-sm' onClick={() => addUnit()}>
                    <FontAwesomeIcon icon={faPlusCircle} className="mr-2" />
                    {t('add')}
                </button>
            </div>
        </Popup>

        <div className="row">

            <div className="col-12 col-sm-8 col-md-7">
                <input type="hidden" value={id || ''} name="id" />

                <div className="col-12">
                    <label htmlFor='product-code'>{t('product code')}:</label>
                    <input type="text"
                        value={code || ''}
                        className="form-control"
                        id="product-code"
                        name="code"
                        placeholder={t("item code")}
                        onChange={e => setCode(e.target.value)}
                        autoComplete="off"
                    />
                </div>

                <div className="col-12">
                    <label htmlFor='product-name'>{t('product name')}:</label>
                    <input type="text"
                        value={name || ''}
                        className="form-control"
                        id="product-name"
                        name="name"
                        placeholder={t("product name")}
                        onChange={e => setName(e.target.value)}
                        autoComplete="off"
                    />
                </div>


                <div className="col-12  mt-4">
                    <label htmlFor='product-price'>{t('item description')}:</label>
                    <input className="form-control" value={description || ''} id={description} onChange={e => setDescription(e.target.value)} />
                </div>




                <div className="col-12 mt-4">
                    <label htmlFor='product-price'>{t('detail.')}:</label>
                    <textarea className="form-control" value={detail || ''} id="exampleFormControlTextarea1" rows="3" onChange={e => setDetail(e.target.value)} />
                </div>


                {/* <div className="col-12">
                    <div className="card mt-4">
                        <div className="card-body  p-3">
                            <label htmlFor='validatedCustomFile'>{t('Image')}:</label>
                            <div className="custom-file">
                                <label className="custom-file-label" htmlFor="validatedCustomFile">{fileName ? fileName : 'Choose image...'}</label>
                                <input
                                    type="file"
                                    name="file"
                                    className="custom-file-input"
                                    id="validatedCustomFile"
                                    onChange={handleChange}
                                    accept=".jpg,.jpeg,.png"
                                />
                                <div className="invalid-feedback"></div>
                            </div>
                            {
                                tempFile && <div className="  mt-2">
                                    <div className="  p-2 text-center ">
                                        <img src={tempFile} className="img-fluid img-rounded" style={{ maxHeight: 150 }} />
                                    </div>
                                </div>
                            }
                        </div>
                    </div>
                </div> */}



            </div>

            <div className="col-12 col-sm-4 col-md-5">
                <div className="col-12">
                    <label htmlFor='product-name'>{t('Item type')}:</label>
                    <select className="form-control" id="product-name" name="item_type" value={type} onChange={e => setType(e.target.value)} >
                        <option value={0}>{t('Finished Goods')}</option>
                        <option value={1}>{t('Assembly')}</option>
                        <option value={2}>{t('Raw Materials')}</option>
                    </select>
                </div>

                <div className="col-12 mt-4">
                    <label htmlFor='product-category'>{t('item group')}:</label>
                    <select className="custom-select" id="product-category" name="category" value={categoryId} onChange={e => setCategoryId(e.target.value)}>
                        {
                            category?.map((el, index) => <option key={index} value={el.id} >{el.name}</option>)
                        }
                    </select>
                </div>

                <div className="col-12 mt-4">
                    <label htmlFor='product-location'>{t('Location')}:</label>
                    <select className="form-control" id="product-location" name="location" value={parseInt(location || 1)} onChange={e => setLocation(e.target.value)} >
                        <option value={1}>{t('Bangkok')}</option>
                        <option value={2}>{t('Krabi')}</option>
                    </select>
                </div>

                <div className="col-12 mt-4">
                    <label htmlFor='product-location'>{t('Item Source')}:</label>
                    <select className="form-control" id="product-itemSource" name="item_source" value={parseInt(itemSource || 1)} onChange={e => setItemSource(e.target.value)} >
                        <option value={1}>{t('Inside')}</option>
                        <option value={2}>{t('Outside')}</option>
                    </select>
                </div>

                {/* <div className="col-12 mt-4">
                    <label htmlFor='product-stock'>{t('stock')}:</label>
                    <input type="number"
                        value={stock || ''}
                        className="form-control text-right"
                        id="product-stock"
                        name="name"
                        placeholder={t("stock")}
                        onChange={e => setStock(e.target.value)}
                        autoComplete="off"
                    />
                </div> */}

                <div className="col-12 my-4">
                    <label htmlFor='default-unit' className='text-info'>{t('default unit')}:</label>
                    <BaseUnit controller={controller} id='default-unit' />

                    {/**Manual Unit */}
                    {/* <div className=''> 
                        <label htmlFor='product-unit' className='text-info'>{t('unit')}:</label>
                        <div className="card mt-2 border-info">
                            <div className="card-body p-3">
                                <div className='d-none'>
                                    <label htmlFor='product-price'>{t('unit')}:</label>
                                    <input type="text"
                                        value={unit || 0}
                                        className="form-control"
                                        id="product-unit"
                                        name="price"
                                        placeholder={t("Detail")}
                                        onChange={e => setPrice(e.target.value)}
                                        autoComplete="off"
                                    />
                                </div>
                                <div className=''>

                                    <div id='product-prices'>
                                        {unitList && Object.keys(unitList)?.map((keyName, index) => (
                                            <div key={index}>
                                                <div className="input-group mb-3">
                                                    <div className="input-group-prepend">
                                                        <span className="input-group-text">{t('unit')}</span>
                                                    </div>
                                                    <input type="text" className="form-control bg-light" value={unitList[keyName]} readOnly />
                                                    <div className="input-group-append">
                                                        <button className="input-group-text btn btn-danger"
                                                            onClick={() => rmUnitOfMeasure(keyName)}>x</button>
                                                    </div>
                                                </div>
                                            </div>
                                        ))}

                                        <div >
                                            <button
                                                className='btn btn-info btn-sm mb-3'
                                                onClick={() => setClosePopupUnit(false)}>
                                                + {t('add unit')}
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div> */}
                </div>
{/* 
                <div className="col-12 mt-4">
                    <div className=''>
                        <label htmlFor='product-prices ' className='text-warning'>{t('price')} {t('sale/rent')}:</label>
                        <div className="card mt-2 border-warning">
                            <div className="card-body p-3">
                                <div className='d-none'>
                                    <label htmlFor='product-price'>{t('price')} {t('sale/rent')}:</label>
                                    <input type="text"
                                        value={price || 0}
                                        className="form-control"
                                        id="product-price"
                                        name="price"
                                        placeholder={t("Detail")}
                                        onChange={e => setPrice(e.target.value)}
                                        autoComplete="off"
                                    />
                                </div>
                                <div className=''>

                                    <div id='product-prices'>
                                        {
                                            priceList && Object.keys(priceList)?.map((keyName, index) => (
                                                <div key={index}>
                                                    <div className="input-group mb-3">
                                                        <div className="input-group-prepend">
                                                            <span className="input-group-text">{t('unit')}</span>
                                                        </div>
                                                        <input type="text" className="form-control bg-light " value={keyName} readOnly />
                                                        <div className="input-group-prepend">
                                                            <span className="input-group-text">{`=`}</span>
                                                        </div>
                                                        <input type="text" className="form-control bg-light text-right" value={_global.NumberFormat(priceList[keyName], 0)} readOnly />
                                                        <div className="input-group-prepend">
                                                            <span className="input-group-text">{t('baht')}</span>
                                                        </div>
                                                        <div className="input-group-append">
                                                            <button className="input-group-text btn btn-danger"
                                                                onClick={() => rmUnit(keyName)}>x</button>
                                                        </div>
                                                    </div>
                                                </div>
                                            ))
                                        }

                                        <div >
                                            <button
                                                className='btn btn-warning btn-sm mb-3'
                                                onClick={() => setClosePopup(false)}>
                                                + {t('add price')}
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div> */}

                <div className="col-12 mt-3 text-right">
                    <label htmlFor='product-Status' className='mr-2'>{t('Status')}   </label>
                    <BootstrapSwitchButton
                        checked={status === 'Active' ? true : false}
                        onlabel={t('Active')}
                        offlabel={t('Disable')}
                        onChange={(checked) => { setStatus(checked ? 'Active' : 'Disabled') }}
                        size="sm"
                        width={100}
                        onstyle="success"
                        id='product-Status'
                    />
                </div>
            </div>
        </div>

    </div>
}

const searchOrder = async (keyword) => {
    try {
        _global.clearCached();
        return await client.get(`/products?name=${keyword}`);
    } catch (e) {
        console.error('error::', e);
    }
}

const ProductApi = {
    search,
    getProduct,
    getProductList,
    getProductByCat,
    getSummary,
    AddProduct,
    remove,
    searchOrder,
    client,
    patch: async (body, id) => {
        try {
            const response = await client.patch(`/products/${id}`, body);
            return response.data;
        } catch (e) {
            console.error('error patch::', e);
        }
    },
};

export default ProductApi;
import React, { useEffect, useState } from 'react';
import InventoryAdjustApi from '../../api/inventory_adjust';
import Template from '../../component/common/template';
import TemplateSheet from '../../component/common/template-sheet';
import { useTranslation } from 'react-i18next';
import Waiting from '../../component/waiting';
import ApprovalSetupApi from '../../api/approval_setup';
import _ from 'lodash';
import moment from 'moment/moment';
import UserApi from '../../api/user';
import ApprovalApi from '../../api/approval';
import uuid from 'react-uuid';
import { useParams } from "react-router-dom";
import _global from '../../api/global';

const ReceiveJournal = (props) => {
    const { t } = useTranslation();
    let { mode } = useParams();
    const [loading, setLoading] = useState(false);
    const [data, setData] = useState([{ id: 0, no: 'loading...' }]);
    const [editable, setEditable] = useState(false);
    const [viewData, setViewData] = useState(false);
    const [confirmApprove, setConfirmApprove] = useState(false);
    const [form, setForm] = useState({});
    const [searchSource, setSearchSource] = useState({});
    const [approver, setApprover] = useState({});
    const [positions, setPositions] = useState({});
    const [unitOfmeasure, setUnitOfmeasure] = useState([]);

    useEffect(() => {
        getData();
        getApprover()
        getPosition()
    }, [mode]);


    const getPosition = async () => {
        let res = await UserApi.getUserPosition('store');
        //console.log('get pisition res ==> ', res);
        setPositions(res.data);
    }

    const getApprover = async () => {
        let { data } = await ApprovalSetupApi.search('journal');
        //console.log('approver ******', data);
        setApprover(data)
    }

    const updateTable = (row) => {
        // update table
        let tableList = [];
        data.forEach(el => {
            if (parseInt(el.id) === parseInt(row.id)) {
                tableList.push(row)
            } else {
                tableList.push(el);
            }

        })
        let index = _.findIndex(data, { id: row.id });
        // Replace item at index using native splice
        tableList.splice(index, 1, { ...row });
        //console.warn('tableList::', tableList);
        setData(tableList);
    }

    const getData = async () => {
        setEditable(false); //clear
        setViewData(false); //clear

        setLoading(true);

        let unit = await _global.client.get('/v1/unit');

        setUnitOfmeasure(unit.data);

        setTimeout(async () => {
            let date = new Date();
            var from = new Date(date.getFullYear(), date.getMonth() - 1, 26);
            var to = new Date(date.getFullYear(), date.getMonth() + 1, 1);

            let type = _global.getJournalStatus(mode).type;
            let { data } = await InventoryAdjustApi.search(type, 1, from, to);
            setData(data);
            setLoading(false);
        }, 300);
    }

    // save button
    const create = async () => {
        let body = _.cloneDeep(form);
        body.items = JSON.stringify(body.items);
        body.attach = body.attach ? JSON.stringify(body.attach) : null;
        body.type = _global.getJournalStatus(mode).type;
        body.sub_type = 1; // IN=1
        delete body.positions;

        setLoading(true);
        let newData = [];
        if (body.id) {
            //update
            body.status = 1;
            //console.log('update body::', body);
            let { data: res } = await InventoryAdjustApi.update(body, body.id);
            //console.log('res update::', res);
            newData = res.data;
            newData.items = JSON.parse(newData.items);
            newData.attach = JSON.parse(newData.attach);
            updateTable(newData)
        } else {
            //create
            //console.log('create body::', body);
            let { data: res } = await InventoryAdjustApi.create(body);
            //console.log('res create::', res);
            let newRow = res.data;
            newRow.items = JSON.parse(newRow.items);
            newRow.attach = JSON.parse(newRow.attach);
            newData = data.concat(newRow);
            //console.log('after push::', newData);

            // update table
            setData(newData);
            //update form
            setForm({ ...form, ...{ id: res.data.id, positions: positions } })
        }

        // close edit
        setTimeout(() => {
            setLoading(false);
            setViewData(true);
            setTimeout(() => {
                //setEditable(false);
            }, 1000)
        }, 500)
    }

    const remove = async (row) => {
        try {

            //console.log('delete row::', row);
            let { data: res } = await InventoryAdjustApi.remove(row.id);
            //console.log('res delete::', res);

            let newRaw = data.filter(el => el.id !== row.id)
            //console.log('new raw data::', newRaw);
            setData(newRaw);
        } catch (e) {
            console.error('e', e);
        }

    }


    const clickSelectSource = async (source) => {
        setForm({
            ...form,
            create_date: source.order_date,
            no: form.no || '',
            source_no: source.no,
            source_id: source.id,
            source_table: 'journal',
            header: source.header,
            detail: source.detail,
            items: source.items ? source.items.filter(el => el.item > 0) : [],
            positions: positions
        });

        //clear for hidden search result
        setSearchSource([]);
    }

    const sendApprove = async () => {
        try {
            setLoading(true);
            //console.log('form send approve::', form);
            let body = {
                source_des: _global.getJournalStatus(mode).text,
                source_table: "journal",
                source_type: 'stock',
                source_id: form.id,
                source_no: form.no,
                approve_when: "string",
                gps: "0,0",
                payload: JSON.stringify(form),
                uuid: uuid(),
                type: 1
            }
            //console.log('body::', body)
            let res = await ApprovalApi.create(body);
            //console.log('res approval>>', res);

            /**
             * clear status pending
             */
            let bodyUpdate = _.cloneDeep(form);
            bodyUpdate.items = typeof bodyUpdate.items === 'object' ? JSON.stringify(bodyUpdate.items) : bodyUpdate.items;
            bodyUpdate.attach = typeof bodyUpdate.attach === 'object' ? JSON.stringify(bodyUpdate.attach) : bodyUpdate.attach;
            bodyUpdate.type = _global.getJournalStatus(mode).type; //fixed 1 = inventory
            bodyUpdate.status = 2;
            bodyUpdate.uuid = body.uuid;
            delete bodyUpdate.positions;

            //console.log('bodyUpdate>>', bodyUpdate);
            let { data: response } = await InventoryAdjustApi.update(bodyUpdate, bodyUpdate.id);
            let newData = response.data;
            //console.log('<<< bodyUpdate', newData);
            newData.items = JSON.parse(newData.items);
            newData.attach = JSON.parse(newData.attach);
            updateTable(newData);
            setTimeout(() => {
                setEditable(false);
                setLoading(false);
            }, 1000)

        } catch (e) {
            console.warn('error send approve', e)
        }


    }

    const modify = () => { //enable
        setForm({}); // clear First
        setViewData(false)
        setEditable(true)
        setForm(form)
    }

    const controller = ({
        title: mode === 'receive' ? t('receive journal') : t('inventory adjust'),
        mode,
        create,
        remove,
        loading,
        viewData,
        setViewData,
        editable,
        setEditable,
        setForm,
        confirmApprove,
        setConfirmApprove,
        sendApprove,
        form,
        setApprover,
        approver,
        positions,
        default: {
            create_date: new Date(),
            no: (mode === 'receive' ? 'R' : 'A') + Date.now(),
            header: "customer",
            detail: "project",
            positions: positions,
            items: [],
            attach: []
        },
        setSearchSource,
        searchSource,
        clickSelectSource,
        modify,
        unitOfmeasure,
        setUnitOfmeasure,
        approve: {
            source_des: mode === 'receive' ? t('receive journal') : t('inventory adjust'),
            source_table: 'journal',
            modalName: 'addModalReturn',
            updateSource: () => {}
        },
        signature: {
            creator:  mode === 'receive' ? 'ผู้รับ' : 'ผู้ปรับสต๊อก',
            system: 'journal',
            department: 'store',
        }
    })

    return (
        <Template hideToTop={true} hideFooter={true}>
            <TemplateSheet controller={controller}>
                <Waiting loading={loading} />
                <InventoryAdjustApi.TableList data={data} controller={controller} />
            </TemplateSheet>
        </Template>
    );

}

export default ReceiveJournal;
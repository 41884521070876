import React from 'react';
import { useTranslation } from 'react-i18next';
import Template from '../component/template';

const Payment = () => {
    const { t } = useTranslation();
    
    return (
        <Template>
             {t('payment')}
 
        </Template>
    );
}

export default Payment;

import { Link, NavLink } from "react-router-dom";
import SwitchLang from './language';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch } from '@fortawesome/free-solid-svg-icons'
import { useTranslation } from 'react-i18next';
const onEnter = (el) => {
    if (el.key === 'Enter') {
        el.preventDefault();
        let value = el.target.value;
        console.log(value)

        // set null
        el.target.value = '';
    }
}

function Header() {
    const { t } = useTranslation();
    return (
        <header>

            


        </header>
    )
}

export default Header;
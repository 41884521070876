import React from 'react';
import { useState } from 'react';

const closePopup = () => {

}

const Popup = ({ children, name, className }) => {

    return (
        <div id={`popup-${name}`} className={`popup-apsolute ${className}`}>
            <div className='popup-body'>
                {children}
            </div>
        </div>
    );
}

export { Popup, closePopup };

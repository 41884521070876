import React, { useEffect, useState } from 'react';
import _ from 'lodash';
import { useTranslation } from 'react-i18next';
import itemProperty from '../../api/itemProperty';
import ModalNew from '../modalNew';
import ModalConfirm from '../modalConfirm';
import Waiting from '../waiting';
import { Buffer } from 'buffer';
import _global from '../../api/global';

function ItemPropPertiesAttach({ controller }) {
    const { t } = useTranslation();

    const [loading, setLoading] = useState(false);
    const [ext, setExt] = useState("");
    const [fileName, setFileName] = useState('');
    const [itemProp, setItemProp] = useState([]);
    const [itemPropGroup, setItemPropGroup] = useState([]);
    const [formItemProp, setFormItemProp] = useState({ group_name: '', attr_name: '', attr_value: '' });
    const [deleteItemProp, setDeleteItemProp] = useState({ id: '', name: '' });

    let { group_code, id, border, modify } = controller


    const fetchItemProps = async (id) => {
        try {
            let itemProp = await itemProperty.search({
                article: 'items',
                ref_id: id,
                group: group_code
            });
            console.log('component item Property::', itemProp);
            if (itemProp.length > 0) {
                setItemProp(itemProp);
                setFormItemProp({ group_name: itemProp[itemProp.length - 1]?.group_name }); //itemProp[0]?.group_name
                setItemPropGroup([...new Set(itemProp?.map((el) => el.group_name))]?.sort());
            }
        } catch (e) {
            console.error('error get item property::', e);
        }
    }

    useEffect(() => {
        fetchItemProps(id).catch(console.error);
    }, []);

    const handelChangeProperties = el => {
        //console.warn('el::',el.target.files[0])
        if (el.target.name === 'att_file') {
            const fileReader = new FileReader();
            fileReader.readAsDataURL(el.target.files[0], "UTF-8");
            setExt(el.target.files[0].name.split('.').pop());
            setFileName(el.target.files[0].name);

            fileReader.onload = element => {
                //console.warn('file::',element.target.result)
                //setFiles(Buffer.from(e.target.result, 'base64'));
                setFormItemProp({
                    ...formItemProp,
                    [el.target.name]: element.target.result
                })
            };
        }

        setFormItemProp({
            ...formItemProp,
            [el.target.name]: el.target.value
        })
    };

    const confirmDelete = async (el) => {
        setDeleteItemProp({ id: el.target.id, name: el.target.name });
    }

    const ItemProps = {
        put: (data) => {
            console.warn('push =>>> data', data);
            let newItemProps = _.cloneDeep(itemProp);
            newItemProps.push(data);
            //console.warn('add newItemProps::', newItemProps);
            setItemProp(newItemProps);
            setFormItemProp({ group_name: itemProp[itemProp.length - 1]?.group_name });
            setItemPropGroup([...new Set(newItemProps?.map((el) => el.group_name))].sort());
        },
        delete: (id) => {
            //console.warn('remove =>>> id', id);
            let newItemProps = itemProp.filter((el) => Number(el.id) !== Number(id));
            //console.warn('remove newItemProps::', newItemProps);
            setItemProp(newItemProps);
            setFormItemProp({ group_name: itemProp[itemProp.length - 1]?.group_name });
            setItemPropGroup([...new Set(newItemProps?.map((el) => el.group_name))].sort());
        }
    }

    const submitForm = async (el) => {
        el.preventDefault();
        setLoading(true);
        console.warn('formItemProp::', fileName, ext);
        // console.warn('itemProp::', itemProp);
        try {
            if (formItemProp.attr_name === '' || formItemProp.attr_name === undefined) throw { code: 'warn', message: 'Empty Value' };
            let body = {
                ref_id: id,
                article: 'items',
                value_type: 'input',
                att_type: 'text',
                group_code: group_code,
                group_name: formItemProp.group_name,
                attribute: formItemProp.attr_name,
                value: formItemProp.attr_value
            }
            // Attach
            if (formItemProp.att_file) {
                try {
                    let fileBody = {
                        title: fileName.toString(),
                        files: Buffer.from(formItemProp.att_file, 'base64'),
                        ext: ext,
                        setting: JSON.stringify({
                            name: fileName.toString(),
                            ext: ext
                        })
                    }
                    const { data: response } = await _global.client.post("/v1/file", fileBody);
                    console.log('<< upload::', response);
                    if (response.status === 2) {
                        body.att_file = JSON.stringify(_.omit(response.data, 'setting'))
                        //newFiles.push(response.data)
                        //controller.setForm({ ...controller.form, attach: newFiles })
                    }
                } catch (error) {
                    console.log(error)
                }
            }


            let assets = await itemProperty.post(body);
            console.log('<< assets::', assets)

            if (assets.status === 2) {
                let newRow = {
                    ...assets.data,
                    att_file: assets.data.att_file
                        ? JSON.parse(assets.data.att_file)
                        : {}
                }
                await ItemProps.put(newRow);
                document.getElementById(`close-addModalNew-${group_code}`).click();

                setTimeout(() => {
                    setLoading(false);
                }, 800);
            }
        } catch (e) {
            setLoading(false);
            if (e.code === 'warn') {
                console.warn(e.message);
            } else {
                console.error('error add::', e);
            }

        }
    };

    const deleteAttr = async () => {
        setLoading(true);
        //console.log('deleteItemProp::', deleteItemProp)
        try {

            let { data: remove } = await itemProperty.delete(deleteItemProp.id);
            //console.log('remove::', remove)

            if (remove.status === 2) {
                ItemProps.delete(deleteItemProp.id);
                document.getElementById(`close-addModalConfirm-${group_code}`).click();
                setTimeout(() => {
                    setLoading(false);
                }, 800);
            }
        } catch (e) {
            console.error('error remove::', e);
        }
    };

    return (
        <div color=''>
            <Waiting loading={loading} />
            <ModalNew submitForm={submitForm} ModalName={t('Assets')} screen='modal-center' name={group_code}>
                <div className='row'>
                    <div className='col-12 mb-3'>
                        <input type={'text'}
                            className='form-control'
                            name={'group_name'}
                            onChange={(el) => handelChangeProperties(el)}
                            value={(formItemProp.group_name) || ''}
                            placeholder={t('Title')}
                        />
                    </div>

                    <div className='col-12 mb-3'>
                        <div className="input-group">
                            <input type={'text'}
                                className='form-control'
                                name={'attr_name'}
                                onChange={(el) => handelChangeProperties(el)}
                                value={formItemProp.attr_name || ''}
                                placeholder={t('Name')}
                                autoComplete="off"
                            />
                            <div className="input-group-prepend">
                                <span className="input-group-text" id="">:</span>
                            </div>
                            <input type={'text'}
                                className='form-control'
                                name={'attr_value'}
                                onChange={(el) => handelChangeProperties(el)}
                                value={formItemProp.attr_value || ''}
                                placeholder={t('Data')}
                                autoComplete="off"
                            />
                        </div>
                    </div>

                    <div className='col-12 mb-3 '>
                        <div className='d-flex flex-row-reverse mb-2'>
                            <a className="text-right text-decoration-none"
                                data-toggle="collapse"
                                href="#collapseAssets"
                                role="button"
                                aria-expanded="false"
                                aria-controls="collapseAssets">
                                {t('advance +')}
                            </a>
                        </div>
                        <div className="collapse" id="collapseAssets">
                            <div className='row'>
                                <div className='col-12 pt-1 text-danger'>{t('Attach file')} :</div>
                                <div className='col-12'>
                                    <div className="custom-file">
                                        <input
                                            type="file"
                                            className="custom-file-input"
                                            id="customFile"
                                            name={'att_file'}
                                            onChange={(el) => handelChangeProperties(el)}
                                        />
                                        <label
                                            className="custom-file-label text-info"
                                            htmlFor="customFile">{formItemProp.att_file || t('Choose file...')}</label>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </ModalNew>
            <ModalConfirm submitConfirm={deleteAttr} ModalName={t('Confirm')} screen={'bd-example-modal-md'} name={group_code}>
                {t(`Delete "${deleteItemProp.name}" ?`)}
            </ModalConfirm>

            {
                itemPropGroup && itemPropGroup?.map((group, index) =>
                    <div key={index}>
                        <div className='mt-3'><h5>{group}</h5></div>
                        <div className={`${border ? 'card' : ''} `}>
                            <div className={`${border ? 'card-body' : ''}`}>
                                {
                                    itemProp?.filter((g) => g.group_name === group).map((el, index) =>
                                        <div key={index} className='row mb-3 '>
                                            <div className='col-4 '>
                                                {el.attribute}
                                            </div>
                                            <div className='col-6 '>
                                                {
                                                    Object.entries(el.att_file).length > 0
                                                        ? <div>
                                                            <a href={`/file/${el.att_file && el.att_file.name}`} target="_bank" className='text-decoration-none' id={el.att_file && el.att_file.name}>
                                                                {el.value || el.att_file && el.att_file.title}
                                                            </a>
                                                        </div>
                                                        : <div>
                                                            {el.value || el.att_file && el.att_file.title}
                                                        </div>
                                                }

                                            </div>
                                            {
                                                modify
                                                    ? <div className='col-2 text-right'>
                                                        <button
                                                            className='btn btn-danger btn-xs mb-2'
                                                            id={el.id}
                                                            name={el.attribute}
                                                            onClick={(el) => confirmDelete(el)}
                                                            data-toggle="modal"
                                                            data-target={`#addModalConfirm-${group_code}`}
                                                        >X</button>
                                                    </div>
                                                    : ''
                                            }

                                        </div>
                                    )
                                }

                            </div>
                        </div>
                    </div>
                )
            }
            <div className='my-3'>
                {
                    modify ? <button
                        className='btn btn-success btn-xs rounded-pill'
                        onClick={() => setLoading(false)}
                        data-toggle="modal"
                        data-target={`#addModalNew-${group_code}`}
                    >{t('+ create')}</button>
                        : ''
                }

            </div>
        </div>
    )
}

export default ItemPropPertiesAttach;


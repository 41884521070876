import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import UserApi from '../../api/user';
import ApprovalSetupApi from '../../api/approval_setup';
import ApprovalApi from '../../api/approval';
import moment from 'moment';

const Signature = ({ Controller }) => {
    const { t } = useTranslation();
    const [positions, setPositions] = useState({});
    const [approver, setApprover] = useState({});
    let form = Controller.form;

    useEffect(() => {
        getPosition(Controller.signature.department)
        getApprover(Controller.signature.system)
        checkApproveStatus();
    }, [form])

    const getPosition = async (department = 'store') => {
        let res = await UserApi.getUserPosition(department);
        //console.log('get pisition res ==> ', res);
        setPositions(res.data);
    }

    const getApprover = async (system = 'journal') => {
        let { data } = await ApprovalSetupApi.search(system);
        //console.warn('approver ******', data);
        setApprover(data)
    }


    const checkApproveStatus = async () => {
        try {

            let name = Controller.signature.system;
            let id = form.id;
            let res = await ApprovalApi.getBySource(name, id)
            //console.log(':: << res getBySource::', res);
            console.log('approver.length::', approver)
            let newApproval = [];
            if (approver.length) {
                for (let approve of approver) {
                    let entry = res.data.filter(el => el.source_table === approve.source_table && el.user === approve.user && el.uuid === form.uuid);
                    newApproval.push({ ...approve, entry: entry[0] || {} })
                }
                //console.log('===signature===::::', newApproval);

                newApproval?.sort((a, b) => {
                    return a.position_step - b.position_step;
                });

                setApprover(newApproval)
            }

        } catch (e) {
            console.error('e::', e);
        }
    }



    //console.log('approve entry ;:', approver);
    return (
        <div>
            <div className=" font-12  ">
                <div className="row">

                    <div className="col text-center border p-2 m-2"  >

                        <div>{t(Controller.signature.creator)}</div>
                        <div className='my-2 text-white'>.</div>
                        <div className='d-flex mt-2 justify-content-center'>
                            (
                            <select
                                name="owner"
                                className="form-select form-control form-control-sm d-print-noline border-0 text-center w-100"
                                style={{ maxWidth: 150, marginTop: -8, background: 'transparent' }}
                                disabled={Controller.viewData}
                                value={form?.owner || 0}
                                onChange={(el) => Controller.setForm({ ...form, owner: el.target.value })}
                            >
                                {
                                    positions?.length > 0 && positions?.map((obj, index) => (
                                        <option key={index + 1} value={obj.id} >{obj.f_name_th + ' ' + obj.l_name_th}</option>
                                    ))
                                }
                            </select>
                            )
                        </div>
                        <div>วันที่.........../........./..............</div>
                    </div>
                    {
                        approver?.length > 0 && approver?.map((row, index) => (
                            <div key={index + 1} className="col text-center border p-2 m-2" style={{ position: 'relative' }}>
                                <div>{row.sign_title}</div>
                                <div className='my-2'>
                                    {row.entry && Object.keys(row.entry).length > 0
                                        ? row.entry.status === 1
                                            ? <span className='text-success' >
                                                {row.sign_img
                                                    ? <>.<div
                                                        style={{
                                                            position: 'absolute',
                                                            zIndex: 10,
                                                            left: 0,
                                                            top: 5,
                                                            width: '100%',
                                                            border: '0px solid #ccc',
                                                            textAlign: 'center'
                                                        }}>
                                                        <img src={`/assets/images/signature/${row.sign_img}`}
                                                            style={{ maxWidth: 80 }} />

                                                        {/* <div className='font-10 d-flex justify-content-between text-info'
                                                            style={{
                                                                position: 'absolute',
                                                                zIndex: 10,
                                                                width: 60,
                                                                left: '41%',
                                                                bottom: -8,
                                                                // marginLeft: '37%',
                                                                // marginTop: '-7px',
                                                                border: '0px solid #ccc'
                                                            }}>
                                                            <div className=' '>{moment(row.entry.approve_when).format('YYYY')}</div>
                                                            <div className=' '>{moment(row.entry.approve_when).format('MM')}</div>
                                                            <div className=' '>{moment(row.entry.approve_when).format('DD')}</div>
                                                        </div> */}
                                                    </div>
                                                    </>
                                                    : row.sign_text || t('Approved')
                                                }
                                            </span>
                                            : (row.entry.status === 3
                                                ? <span className='text-danger'>{row.entry.message || t('Rejected')}</span>
                                                : <span className={row.sign_text ? 'text-magenta' : 'text-white'}>{row.sign_text ? t('Pending') : '.'}</span>)
                                        : <span className='text-white'>.</span>
                                    }
                                </div>
                                <div className='d-flex mt-2 justify-content-center'>
                                    (
                                    <span className='mx-2 font-13'>{row.format_row3}</span>
                                    )
                                </div>
                                <div>
                                    {row.entry && Object.keys(row.entry).length > 0
                                        ? row.entry.status === 1
                                            ? <span className=' ' >
                                                <div className=''>
                                                    <div className=' '>
                                                        วันที่ ...<span className='text-info'>{moment(row.entry.approve_when).format('DD')}</span>
                                                        ../..<span className='text-info'>{moment(row.entry.approve_when).format('MM')}</span>
                                                        ../..<span className='text-info'>{moment(row.entry.approve_when).format('YYYY')}</span>...
                                                    </div>
                                                </div>
                                            </span>
                                            : <span className='mx-2 '>{row.format_row2}</span>
                                        : <span className='mx-2 '>{row.format_row2}</span>
                                    }
                                    {/* {row.format_row2} */}
                                </div>
                            </div>
                        ))
                    }
                </div >
            </div >
        </div >
    );
}

export default Signature;

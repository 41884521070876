import { t } from 'i18next';
import React, { useEffect, useState } from 'react';
import _global from './global';
import DataTable from 'react-data-table-component';
import ArrowDownward from '@material-ui/icons/ArrowDownward';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import DatePicker, { setDefaultLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import {
    faBan,
    faCheck,
    faEdit, faEye, faFloppyDisk, faPlusCircle, faPowerOff, faTimes, faTrashAlt
} from '@fortawesome/free-solid-svg-icons';
import _ from 'lodash';
import moment from 'moment/moment';
import ApprovalApi from './approval';
import FileUpload from '../component/common/fileUpload';
import Btn from '../component/journal/journal-btn';
import Signature from '../component/common/signature';
import SearchItem from '../component/common/searchItems';

const search = async (type, sub_type, from, to) => {
    try {
        let response = await _global.client.get(`/v1/journal`, {
            params:
            {
                type: type,
                sub_type: sub_type || 2,
                from: from,
                to: to
            }
        });

        let list = [];
        for (let row of response.data) {
            list.push({
                ...row,
                items: row.items ? JSON.parse(row.items) : [],
                attach: row.attach ? JSON.parse(row.attach) : []
            })
        }
        //console.log('response::', list)
        return { data: list };
    } catch (error) {
        console.error(error);
    }
}

const getData = async (id) => {
    try {
        let response = await _global.client.get(`/v1/journal/${id}`);
        //console.log('response::', response.data)
        return response;
    } catch (error) {
        console.error(error);
    }
}

const remove = async (id) => {
    try {
        return await _global.client.delete(`/v1/journal/${id}`);
    } catch (e) {
        console.error('error::', e);
    }
}


const create = async (body) => {
    try {
        return await _global.client.post(`/v1/journal`, body);
    } catch (e) {
        console.error('error::', e);
    }
}

const update = async (body, id) => {
    try {
        return await _global.client.patch(`/v1/journal/${id}`, body);
    } catch (e) {
        console.error('error::', e);
    }
}


const TableList = (props) => {

    const [deleteRow, setDeleteRow] = useState({});
    const view = (row) => {
        edit(row)
        props.controller.setViewData(true)
    }
    const edit = (row) => {
        props.controller.setForm({}); // clear First
        props.controller.setViewData(false)
        props.controller.setEditable(true)
        props.controller.setForm(row)
    }

    const newDoc = () => {
        props.controller.setEditable(true)
        props.controller.setForm(props.controller.default)
    }

    const confirmDelete = () => {
        props.controller.remove(deleteRow)
    }

    const confirmSendApprove = () => {
        //props.controller.sendApprove()
    }


    const Title = ({ controller }) => {
        return <>
            <div className='row'>
                <div className='col'>
                    <h3 className=" mb-2 text-gray-800">{t('item journal')}</h3>
                </div>
                <div className='col text-right'>
                    <button
                        type="button"
                        className='btn btn-outline-success btn-sm'
                        onClick={() => newDoc()}
                        data-toggle="modal"
                        data-target="#addModalOrder"
                    >
                        <FontAwesomeIcon icon={faPlusCircle} /> {t('Add')}
                    </button>
                </div>
            </div>

        </>
    }
    return <div>
        <FormAddData controller={props.controller} />
        <div className="modal fade" id="modalDeleteJournal" tabIndex={-1} role="dialog">
            <div className="modal-dialog" role="document">
                <div className="modal-content">
                    <div className="modal-header bg-danger text-white">
                        <h5 className="modal-title">Confirm</h5>
                        <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">×</span>
                        </button>
                    </div>
                    <div className="modal-body">
                        <p>{t('comfirn delete')}</p>
                    </div>
                    <div className="modal-footer">
                        <button type="button" className="btn btn-danger" onClick={() => confirmDelete()} data-dismiss="modal" ><FontAwesomeIcon icon={faTrashAlt} /> {t('delete')}</button>
                        <button type="button" className="btn btn-secondary" data-dismiss="modal"><FontAwesomeIcon icon={faPowerOff} /> {t('cancel')}</button>
                    </div>
                </div>
            </div>
        </div>

        <div className="modal fade" id="modalConfirmSendApprove" tabIndex={-1} role="dialog">
            <div className="modal-dialog" role="document">
                <div className="modal-content">
                    <div className="modal-header bg-info text-white">
                        <h5 className="modal-title">Confirm</h5>
                        <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">×</span>
                        </button>
                    </div>
                    <div className="modal-body">
                        <p>{t('comfirn delete')}</p>
                    </div>
                    <div className="modal-footer">
                        <button type="button" className="btn btn-danger" onClick={() => confirmSendApprove()} data-dismiss="modal" ><FontAwesomeIcon icon={faTrashAlt} /> {t('delete')}</button>
                        <button type="button" className="btn btn-secondary" data-dismiss="modal"><FontAwesomeIcon icon={faPowerOff} /> {t('cancel')}</button>
                    </div>
                </div>
            </div>
        </div>

        <div className={`${props.controller.editable && 'd-none'}`}>
            <DataTable
                title={<Title controller={props.controller} />}
                noDataComponent={t('There are no records to display')}
                pagination
                paginationPerPage={50}
                paginationRowsPerPageOptions={[10, 20, 50, 100, 200, 300]}
                selectableRowsComponentProps={{ indeterminate: isIndeterminate => isIndeterminate }}
                sortIcon={<ArrowDownward />}
                dense
                data={props.data || []}
                columns={[
                    {
                        name: t('date'),
                        selector: row => moment(row.create_date).format('YYYY-MM-DD'),
                        sortable: true,
                        minWidth: 100,
                        maxWidth: 120,
                        id: "create_date"
                    },
                    {
                        name: t('document no'),
                        selector: row => row.no,
                        sortable: true,
                        maxWidth: '150px',
                        id: "no"
                    },
                    {
                        name: t('customer name'),
                        selector: row => row.header,
                        sortable: true,
                        id: "header"
                    },
                    {
                        name: t('document source'),
                        selector: row => row.source_no,
                        sortable: true,
                        maxWidth: '150px',
                        id: "source_no"
                    },
                    {
                        name: t('status'),
                        selector: row => <div className={`${_global.JournalStatus(row.status).className}`}>{_global.JournalStatus(row.status).text}</div>,
                        sortable: true,
                        maxWidth: '150px',
                        id: "status"
                    },
                    {
                        name: t('Action'),
                        selector: row => row.id,
                        id: "action",
                        width: '220px',
                        cell: (row) => <div className='w-100 text-right'>
                            <Btn row={row} view={view} edit={edit} setDeleteRow={setDeleteRow} />
                        </div >
                    }

                ]}

                sortFunction={_global.customSort}
                defaultSortFieldId="code"
                customStyles={{
                    header: {
                        style: {
                            minHeight: '56px',
                        },
                    },
                    headCells: {
                        style: {
                            '&:not(:last-of-type)': {
                                borderTopStyle: 'solid',
                                borderTopWidth: '1px',
                                borderTopColor: '#ccc',
                                borderLeftStyle: 'solid',
                                borderLeftWidth: '1px',
                                borderLeftColor: '#ccc',
                                borderTop: "5px solid black",
                                borderBottom: "1px double #ccc"
                            },
                            "&:last-child": {
                                borderTopStyle: 'solid',
                                borderTopWidth: '1px',
                                borderTopColor: '#ccc',
                                borderRightStyle: 'solid',
                                borderRightWidth: '1px',
                                borderRightColor: '#ccc',
                                borderLeftStyle: 'solid',
                                borderLeftWidth: '1px',
                                borderLeftColor: '#ccc',
                                borderTop: "5px solid black",
                                borderBottom: "1px double #ccc"
                            }
                        },
                    },
                    rows: {
                        style: {
                            borderStyle: 'solid',
                            borderWidth: '1px',
                            borderColor: '#ccc'
                        },
                    },
                    cells: {
                        style: {
                            '&:not(:last-of-type)': {
                                borderRightStyle: 'solid',
                                borderRightWidth: '1px',
                                borderRightColor: '#ccc'
                            },
                        },
                    },
                }}
                highlightOnHover
                pointerOnHoverF
                {...props}
            />
        </div>
    </div>;


}


const FormAddData = ({ controller }) => {
    const [showModal, setShowModal] = useState(false);
    const [fadeHide, setFadeHide] = useState('');
    const [editGroup, setEditGroup] = useState([2, 3, 4]);
    const [searchResult, setSearchResult] = useState([]);

    let form = controller.form;
    //console.log('searchOrder::', controller);

    useEffect(() => {
        if (!form.source_no) {
            setShowModal(true)
        } else {
            setShowModal(false)
        }

        checkApproveStatus()
    }, [form])


    const checkApproveStatus = async () => {
        try {

            let name = 'journal';
            let id = form.id;
            let res = await ApprovalApi.getBySource(name, id)
            //console.log('res getBySource::', res);

            let newApproval = [];
            if (controller.approver.length) {
                for (let approve of controller.approver) {
                    let entry = res.data.filter(el => el.source_table === approve.source_table && el.user === approve.user && el.uuid === form.uuid);
                    newApproval.push({ ...approve, entry: entry[0] || {} })
                }
                //console.log('::::', newApproval);
                controller.setApprover(newApproval)
            }

        } catch (e) {
            console.error('e::', e);
        }
    }

    const deleteLine = (item) => {
        let newItems = form.items.filter(el => el.id !== item.id);
        controller.setForm({ ...form, ...{ items: newItems } })
    }

    const updateForm = (name, value) => {
        let newForm = {
            ...form,
            [name]: value
        }
        controller.setForm(newForm)
    }

    const updateLine = (name, event) => {
        console.log('name::', name, '=>', event.target.value);


        const element = event.target.parentElement;
        const tr = element.closest("tr");
        let id = tr.id;

        let newItem = [];
        for (let item of form.items) {
            if (Number(item.id) === Number(id)) {
                let value = name === 'base_unit' ? event.target.id : event.target.value;

                // Validate from sale line
                // if (name === 'qty') {
                //     console.log('event.target.value > item.qty', event.target.value, item.qty)
                //     if (event.target.value > item.qty) {
                //         value = item.qty;
                //     } else {
                //         value = event.target.value;
                //     }
                // }

                newItem.push({
                    ...item,
                    [name]: value
                })
            } else {
                newItem.push(item);
            }
        }
        console.log('new item::', newItem);

        controller.setForm({ ...form, items: newItem })
    }

    const btnSendApprove = () => {
        controller.sendApprove();
        controller.setConfirmApprove(false)
        console.log('send approve')
    }

    const ModalSearch = ({ children, ModalName }) => {

        return <>

            <div className={`absolute-card shadow ${showModal ? 'd-block' : 'd-none'}`} >
                <div className="card  shadow">
                    <div className="card-header bg-warning">
                        <button type="button" className="close" onClick={() => setShowModal(false)}>
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div className="card-body text-start">
                        <h5 className="card-title">{t('document source')}</h5>
                        <input
                            name="source_no"
                            type={'text'}
                            className="form-control form-control-sm d-print-noline"
                            placeholder={t('input sale order number.')}

                            value={form.source_no || ''}
                            onChange={(el) => controller.getSource(el.target.value)}
                        />

                    </div>
                </div>
            </div>

            {/* MOdal Confirm */}
            <div className={`absolute-card shadow ${controller.confirmApprove ? 'd-block' : 'd-none'}`} >
                <div className="card  shadow">
                    <div className="card-header bg-success">
                        <button type="button" className="close" onClick={() => controller.setConfirmApprove(false)}>
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div className="card-body text-left">
                        <h5 className="card-title">{t('Confirm')}</h5>
                        <div className='d-flex justify-content-between'>
                            <button className='btn btn-secondary btn-sm' onClick={() => controller.setConfirmApprove(false)}>
                                <FontAwesomeIcon icon={faTimes} /> {t('cancel')}
                            </button>
                            <button className='btn btn-primary btn-sm' onClick={() => btnSendApprove()} >
                                <FontAwesomeIcon icon={faCheck} />  {t('Confirm')}
                            </button>
                        </div>
                    </div>
                </div>
            </div>

            <div className={`shadow search-order-result ${controller.searchOrder.length > 0 ? 'd-block' : 'd-none'} `} >
                <div className='d-flex w-100 justify-content-end'>
                    <div className='cursor-pointer text-center'
                        style={{ width: 30 }}
                        onClick={() => controller.setSearchOrder([])}>
                        x
                    </div>
                </div>
                <div style={{ padding: 10 }}>
                    {
                        controller.searchOrder.length > 0 && controller.searchOrder?.map((el, index) => (
                            <div className="row row-hover" key={index + 1} onClick={() => controller.clickSelectSource(el)}>
                                <div className="col-3 border border-secondary bg-dark text-white pb-2"><b>{el.document_no}</b></div>
                                <div className="col border border-secondary">
                                    <b>{moment(el.order_date).format('MM/DD/YYYY')}</b></div>
                                <div className="col border border-secondary">{el.cust_name}</div>
                                <div className={`col border border-secondary ${_global.JobStatus(el.status).class} text-center`}>{_global.JobStatus(el.status).text}</div>
                            </div>
                        ))
                    }

                </div>
            </div>
        </>
    }

    return <>
        <div className={`${!controller.editable && 'd-none'} mb-5`} style={{
            border: '0px solid #ccc',
            padding: 0,
            background: "#fff",
            position: 'relative'
        }}>
            <ModalSearch />
            <SearchItem.modal data={searchResult} controller={controller} setData={setSearchResult} />
            <table className='table table-bordered' >
                <thead>
                    <tr>
                        <th className='text-center' colSpan={'4'}>
                            <h5>{t('inventory request')}</h5>
                            <div className='font-13'>Request Inventory</div>
                        </th>
                        <th colSpan={'2'} width="20%" className='text-center'>
                            <img src="/assets/images/thammasak-q.png" className='w-100' style={{ maxWidth: 120 }} />
                        </th>
                    </tr>

                    <tr className='font-12 font-weight-bold'>
                        <td colSpan={'3'} rowSpan={'3'}>
                            <div className='mb-5 d-flex'>
                                <span style={{ minWidth: 50 }}>{t('customer name')}</span> :
                                <span className='text-primary w-100 ml-2' style={{ marginTop: -6 }}>
                                    <textarea
                                        className="form-control d-print-noline font-12"
                                        id="header"
                                        rows="3"
                                        // readOnly={editGroup.includes(form.status) || controller.viewData}
                                        readOnly={controller.viewData}
                                        value={form.header || ''}
                                        onChange={(el) => updateForm('header', el.target.value)}
                                    />
                                </span>
                            </div>
                            <div className='d-flex'>
                                <span style={{ minWidth: 50 }}>{t('Project')}</span> :
                                <span className='text-primary w-100 ml-2' style={{ marginTop: -6 }}>
                                    <input
                                        name="detail"
                                        type={'text'}
                                        className="form-control form-control-sm d-print-noline"
                                        // readOnly={editGroup.includes(form.status) || controller.viewData}
                                        readOnly={controller.viewData}
                                        defaultValue={form.detail || ''}
                                        onChange={(el) => updateForm('detail', el.target.value)}
                                    />
                                </span>
                            </div>
                        </td>
                        <td width="10%" style={{ minWidth: 120 }}>{t('document no')}</td>
                        <td colSpan={'2'}>
                            <input
                                name="no"
                                type={'text'}
                                className="form-control form-control-sm d-print-noline"
                                // readOnly={editGroup.includes(form.status) || controller.viewData}
                                readOnly={controller.viewData}
                                defaultValue={form.no || ''}
                                onChange={(el) => updateForm('no', el.target.value)}
                            />
                        </td>
                    </tr>

                    <tr className='font-12 font-weight-bold'>
                        <td>{t('document source')}</td>
                        <td colSpan={'2'}>
                            <input
                                name="source_no"
                                type={'text'}
                                className="form-control form-control-sm d-print-noline"
                                placeholder={t('input sale order number.')}
                                // readOnly={editGroup.includes(form.status) || controller.viewData}
                                readOnly={controller.viewData}
                                value={form.source_no || ''}
                                onChange={(el) => controller.getSource(el.target.value)}
                            />
                        </td>
                    </tr>

                    <tr className='font-12 font-weight-bold'>
                        <td>{t('date request')}</td>
                        <td colSpan={'2'}>
                            <DatePicker
                                selected={form.create_date ? new Date(form.create_date) : new Date()}
                                dateFormat="dd/MM/yyyy"
                                className="form-control form-control-sm d-print-noline"
                                // readOnly={editGroup.includes(form.status) || controller.viewData}
                                readOnly={controller.viewData}
                                onChange={(date) => updateForm('create_date', moment(date).toISOString())}
                            />
                        </td>
                    </tr>

                    <tr className='font-12 font-weight-bold'>
                        <td width="2%">{t('No.')}</td>
                        <td width="15%">{t('item code')}</td>
                        <td>{t('request descripton')}</td>
                        <td>{t('request amount')}</td>
                        <td>{t('unit')}</td>
                        <td>{t('remark')}</td>
                    </tr>
                </thead>

                <tbody className='font-12'>
                    {
                        form.items?.length > 0 && form.items.map((item, index) => (
                            <tr key={index + 1} id={item.id}>
                                <td className='text-center'>{index + 1}</td>
                                <td>{item?.item_code}</td>
                                <td>{item.name}</td>
                                <td className=''>
                                    <input
                                        name="qty"
                                        type={'number'}
                                        className="form-control form-control-sm d-print-noline text-right"
                                        // readOnly={editGroup.includes(form.status) || controller.viewData}
                                        readOnly={controller.viewData}
                                        value={Number(item?.qty)}
                                        style={{ marginTop: -7 }}
                                        onChange={(el) => updateLine('qty', el)}
                                    />
                                </td>
                                <td>
                                    <div className="input-group" style={{ marginTop: '-5px' }}>
                                        <input
                                            type="text"
                                            className="form-control form-control-sm text-left d-print-noline"
                                            name={`base_unit`}
                                            value={item.base_unit ? controller.unitOfmeasure.filter(el => el.id === Number(item.base_unit))[0].name : ''}
                                            readOnly={true}
                                        />
                                        <div className="input-group-append disabled-print">
                                            <button type="button" className="btn btn-outline-secondary btn-sm dropdown-toggle dropdown-toggle-split" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                <span className="sr-only">Toggle Dropdown</span>
                                            </button>
                                            <div className="dropdown-menu">
                                                {
                                                    controller.unitOfmeasure.length && controller.unitOfmeasure.map((element, i) => (
                                                        <button
                                                            className="dropdown-item"
                                                            id={element.id}
                                                            name="units"
                                                            key={i}
                                                            value={element.name}
                                                            disabled={controller.viewData}
                                                            onClick={(el) => updateLine('base_unit', el, index)}
                                                        >
                                                            {element.name}
                                                        </button>
                                                    ))
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </td>
                                <td>
                                    <div style={{ position: 'relative' }}>
                                        <input
                                            name="remark"
                                            type={'text'}
                                            className="form-control form-control-sm d-print-noline"
                                            readOnly={controller.viewData}
                                            value={item?.remark}
                                            style={{ marginTop: -7 }}
                                            onChange={(el) => updateLine('remark', el)}
                                        />
                                        <div
                                            className={`delete-row text-danger cursor-pointer disabled-print ${editGroup.includes(form.status) ? 'd-none' : ''}`}
                                            onClick={() => deleteLine(item)}
                                        >
                                            <FontAwesomeIcon icon={faBan} />
                                        </div>
                                    </div>
                                </td>
                            </tr>
                        ))

                    }
                </tbody>
                {
                    !controller.viewData && <tbody className='font-12'>
                        <tr id="new-row">
                            <td className='text-center'>#</td>
                            <td colSpan={`6`}>
                                <div style={{ marginTop: '-6px', marginBottom: '-6px' }}>
                                    <SearchItem.itemCode
                                        controller={controller}
                                        name={`item_code`}
                                        value={form?.newCode}
                                        setValue={controller.setForm}
                                        setData={setSearchResult}
                                        nextInput='name'
                                    />
                                </div>
                            </td>
                        </tr>
                    </tbody>
                }
                <tfoot>
                    <tr>
                        <td colSpan={'6'} className="font-11">
                            <div>{`1) ใบรับสินค้าจะต้องมีลายเซนต์ของหัวหน้าฝ่ายขึ้นไปเป็นผู้อนุมัติทุกครั้ง`}</div>
                            <div>{`2) ผู้รับสินค้าโปรดตรวจสอบสินค้า จำนวนที่ได้รับ และดำเนินการเซนต์ผู้รับสินค้าทุกครั้งก่อนออกจากสโตร์`}</div>
                        </td>
                    </tr>
                    <tr>
                        <td colSpan={'6'} className="font-12">
                            <Signature Controller={controller} />
                        </td>
                    </tr>
                    <tr>
                        <td colSpan={`6`} className={`disabled-print`}>
                            <FileUpload.FormUpload controller={controller} />
                        </td>
                    </tr>
                </tfoot>
            </table>
        </div>
    </>
}


const JournalApi = {
    search,
    getData,
    TableList,
    FormAddData,
    remove,
    create,
    update
};

export default JournalApi;
import $ from 'jquery';
import React, { useEffect, useState } from 'react';
import { Buffer } from 'buffer';
import OrdersApi from '../api/orders';
import Template from '../component/template';
import Table from '../component/table-orders';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faFloppyDisk, faPowerOff
} from '@fortawesome/free-solid-svg-icons';
import moment from 'moment/moment';

const Orders = () => {
    const { t } = useTranslation();
    const [orders, setOrders] = useState(null);
    const [name, setName] = useState('');
    const [id, setId] = useState('');
    const [detail, setDetail] = useState('');
    const [price, setPrice] = useState(0);
    const [type, setType] = useState(0);
    const [categoryId, setCategoryId] = useState(0);
    const [status, setStatus] = useState('Active');
    const [files, setFiles] = useState("");
    const [ext, setExt] = useState("");

    useEffect(() => {

        localStorage.removeItem('cart-list')
        const fetchData = async () => {
            try {

                let order = await OrdersApi.getOrderList('2022-07-15T17:00:00.000Z', moment().format('YYYY-MM-DDThh:mm:ss.SSSZ').toString());
                setOrders(order);
            } catch (e) {
                console.error('error get Order::', e)
            }

        }
        fetchData().catch(console.error);


    }, [])


    const deleteProduct = (id) => {

        let data = orders.filter((e) => e.id !== id);
        setOrders(data);
        OrdersApi.remove(id)

        console.log('new data::', orders)
    }

    const submitForm = async (e) => {
        e.preventDefault();
        try {

            if (!name) {
                throw new Error('please input name')
            }
            let body = {
                name: name,
                detail: detail,
                img: files,
                img_ext: ext,
                price: price,
                category: categoryId,
                status: status,
                item_type: type
            }

            let token = await OrdersApi.getToken()
            if (id !== '') {
                console.log('sent update')
                let response = await OrdersApi.client.patch(`/products/${id}`, body, {
                    headers: { "Authorization": 'Bearer ' + token }
                });
                setId('');
                console.log(id !== '' ? true : false);
                if (response.data.status === 2) {
                    console.log('items update::', orders)
                    $('body').find('button.close').click();
                }

            } else {
                let response = await OrdersApi.client.post(`/products`, body, {
                    headers: { "Authorization": 'Bearer ' + token }
                });

                console.log('response::', response.data.data);
                if (response.data.status === 2) {
                    let newData = orders;
                    newData.push(response.data.data)
                    setOrders(newData)

                    console.log('items::', orders)
                    $('body').find('button.close').click();
                }
            }


        } catch (e) {
            warning('Error:: ' + e.message);
            $('body').find('input.product-name').focus();
            // modalAddProduct.dispatchEvent(new KeyboardEvent('keydown', {
            //     'isTrusted': true,
            //     'key': 'Escape',
            //     'code': 'Escape',
            //     location: 0
            // }));
        }
    };


    const warning = (message) => {
        alert(message);
    }


    const handleChange = e => {
        const fileReader = new FileReader();
        fileReader.readAsDataURL(e.target.files[0], "UTF-8");
        setExt(e.target.files[0].name.split('.').pop());

        fileReader.onload = e => {
            setFiles(Buffer.from(e.target.result, 'base64'));
        };
    };

    console.log('orders::', orders)
    return (
        <Template >
            <Table
                data={orders}
                deleteProduct={deleteProduct}
                setName={setName}
                setDetail={setDetail}
                setId={setId}
                setPrice={setPrice}
                setCategoryId={setCategoryId}
                setStatus={setStatus}
                setType={setType}
                submitForm={submitForm}
                OrdersApi={OrdersApi}
            />
        </Template>
    );
}

export default Orders;

import React from 'react';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faFloppyDisk, faPowerOff
} from '@fortawesome/free-solid-svg-icons';

const ModalNew = (props) => {
    const { submitForm, ModalName, screen, name } = props;
    const { t } = useTranslation();


    return (
        <div className={`modal fade ${screen ?? 'modal-fullscreen'}`} id={`addModalNew-${name}`} tabIndex={-1} role="dialog" aria-hidden="true" data-backdrop="static" data-keyboard="false">
            <div className="modal-dialog modal-fullscreen" role="document">
                <div className="modal-content">
                    <div className="modal-header bg-primary text-white">
                        <h5 className="modal-title modal-add-title">{ModalName}</h5>
                        <button className="close" type="button" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">×</span>
                        </button>
                    </div>
                    <div className="modal-body modalNew-add-detail">
                        {props.children}
                    </div>
                    <div className="modal-footer bg-primary ">
                        <button className="btn btn-success btn-sm" type="button" onClick={submitForm}><FontAwesomeIcon icon={faFloppyDisk} /> {t('save')}</button>
                        <button className="btn btn-secondary btn-sm" id={`close-addModalNew-${name}`} type="button" data-dismiss="modal"><FontAwesomeIcon icon={faPowerOff} /> {t('close')}</button>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default ModalNew;

import React, { useEffect, useState } from 'react';
import JournalApi from '../../api/journal';
import Template from '../../component/common/template';
import TemplateSheet from '../../component/common/template-sheet';
import { useTranslation } from 'react-i18next';
import Waiting from '../../component/waiting';
import OrdersApi from '../../api/orders';
import ApprovalSetupApi from '../../api/approval_setup';
import _ from 'lodash';
import moment from 'moment/moment';
import UserApi from '../../api/user';
import ApprovalApi from '../../api/approval';
import uuid from 'react-uuid';
import _global from '../../api/global';

const ItemJournal = (props) => {
    const { t } = useTranslation();
    const [loading, setLoading] = useState(false);
    const [data, setData] = useState([{ id: 0, no: 'loading...' }]);
    const [editable, setEditable] = useState(false);
    const [viewData, setViewData] = useState(false);
    const [confirmApprove, setConfirmApprove] = useState(false);
    const [form, setForm] = useState({});
    const [searchOrder, setSearchOrder] = useState({});
    const [approver, setApprover] = useState({});
    const [positions, setPositions] = useState({});
    const [unitOfmeasure, setUnitOfmeasure] = useState([]);

    useEffect(() => {
        getData();
        getApprover()
        getPosition()
    }, []);

    const getPosition = async () => {
        let res = await UserApi.getUserPosition('store');
        //console.log('get pisition res ==> ', res);
        setPositions(res.data);
    }

    const getApprover = async () => {
        let { data } = await ApprovalSetupApi.search('journal');
        //console.log('approver ******', data);
        setApprover(data)
    }

    const updateTable = (row) => {
        // update table
        let tableList = [];
        data.forEach(el => {
            if (parseInt(el.id) === parseInt(row.id)) {
                tableList.push(row)
            } else {
                tableList.push(el);
            }

        })
        let index = _.findIndex(data, { id: row.id });
        // Replace item at index using native splice
        tableList.splice(index, 1, { ...row });
        //console.log('tableList::', tableList);
        setData(tableList);
    }

    const getData = async () => {
        setLoading(true);


        let unit = await _global.client.get('/v1/unit');

        setUnitOfmeasure(unit.data);


        setTimeout(async () => {
            let date = new Date();
            var from = new Date(date.getFullYear(), date.getMonth() - 1, 26);
            var to = new Date(date.getFullYear(), date.getMonth() + 1, 1);

            let { data } = await JournalApi.search(1, 2, from, to);
            setData(data);
            setLoading(false);
        }, 300);
    }

    // save button
    const create = async () => {
        let body = _.cloneDeep(form);
        body.items = JSON.stringify(body.items);
        body.attach = body.attach ? JSON.stringify(body.attach) : null;
        body.type = 1; //fixed 1 = inventory
        body.sub_type = 2; // OUT=2
        delete body.positions;

        setLoading(true);
        let newData = [];
        if (body.id) {
            //update
            body.status = 1;
            body.uuid = uuid();
            //console.log('update body::', body);
            let { data: res } = await JournalApi.update(body, body.id);
            //console.log('res update::', res);
            newData = res.data;
            newData.items = JSON.parse(newData.items);
            newData.attach = JSON.parse(newData.attach);
            updateTable(newData)
        } else {
            //create
            //console.log('create body::', body);
            let { data: res } = await JournalApi.create(body);
            //console.log('res create::', res);
            let newRow = res.data;
            newRow.items = JSON.parse(newRow.items);
            newRow.attach = JSON.parse(newRow.attach);
            newData = data.concat(newRow);
            //console.log('after push::', newData);

            // update table
            setData(newData);
            //update form
            setForm({ ...form, ...{ id: res.data.id, positions: positions } })
        }

        // close edit
        setTimeout(() => {
            setLoading(false);
            setViewData(true);
            setTimeout(() => {
                //setEditable(false);
            }, 1000)
        }, 500)
    }

    const remove = async (row) => {
        try {

            //console.log('delete row::', row);
            let { data: res } = await JournalApi.remove(row.id);
            //console.log('res delete::', res);

            let newRaw = data.filter(el => el.id !== row.id)
            //console.log('new raw data::', newRaw);
            setData(newRaw);
        } catch (e) {
            console.error('e', e);
        }

    }

    const getSource = async (value) => {

        setForm({
            ...form,
            source_no: value
        })
        let res = await OrdersApi.searchOrder(value)
        setSearchOrder(res)

    }

    const clickSelectSource = async (order) => {
        if (order.status !== 4) {
            alert(t('status must be Approved'));
        } else {
            setForm({
                ...form,
                create_date: order.order_date,
                no: form.no || '',
                source_no: order.document_no,
                source_id: order.id,
                source_table: 'journal',
                header: order.cust_name,
                detail: order.cust_project,
                items: order.items ? JSON.parse(order.items).filter(el => el.item > 0) : [],
                positions: positions
            });

            //clear for hidden search result
            setSearchOrder([]);
        }

    }

    const sendApprove = async () => {
        try {
            setLoading(true);
            //console.log('form send approve::', form);
            let body = {
                source_des: 'ใบเบิกสินค้า',
                source_table: "journal",
                source_id: form.id,
                source_no: form.no,
                approve_when: "string",
                gps: "0,0",
                payload: JSON.stringify(form),
                uuid: uuid(),
                type: 2
            }
            //console.log('body::', body)
            let res = await ApprovalApi.create(body);
            //console.log('res approval>>', res);

            /**
             * clear status pending
             */
            let bodyUpdate = _.cloneDeep(form);
            bodyUpdate.items = JSON.stringify(bodyUpdate.items);
            bodyUpdate.attach = typeof bodyUpdate.attach === 'object' ? JSON.stringify(bodyUpdate.attach) : bodyUpdate.attach;
            bodyUpdate.type = 1; //fixed 1 = inventory
            bodyUpdate.status = 2;
            bodyUpdate.uuid = body.uuid;
            delete bodyUpdate.positions;

            let { data: response } = await JournalApi.update(bodyUpdate, bodyUpdate.id);
            let newData = response.data;
            newData.items = newData.items?.length > 0 ? JSON.parse(newData.items) : [];
            newData.attach = JSON.parse(newData.attach);
            updateTable(newData);

            setTimeout(() => {
                setEditable(false);
                setLoading(false);
            }, 1000)

        } catch (e) {
            console.warn('error send approve', e)
        }


    }

    const modify = () => { //enable
        setForm({}); // clear First
        setViewData(false)
        setEditable(true)
        setForm(form)
    }

    const controller = ({
        create,
        remove,
        loading,
        viewData,
        setViewData,
        editable,
        setEditable,
        getSource,
        setForm,
        confirmApprove,
        setConfirmApprove,
        sendApprove,
        form,
        setApprover,
        approver,
        positions,
        unitOfmeasure,
        setUnitOfmeasure,
        default: {
            create_date: new Date(),
            no: 'RE' + Date.now(),
            header: "xxx",
            detail: "project",
            positions: positions
        },
        setSearchOrder,
        searchOrder,
        clickSelectSource,
        modify,
        approve: {
            source_des: 'รับคืนสินค้า',
            source_table: 'journal',
            modalName: 'addModalReturnReceive',
            updateSource: () => { }
        },
        signature: {
            creator: t('receiver return'),
            system: 'journal',
            department: 'store',
        }
    })
    console.log('data', data, 'form>', form)
    return (
        <Template hideToTop={true} hideFooter={true}>
            <TemplateSheet controller={controller}>
                <Waiting loading={loading} />
                <JournalApi.TableList data={data} controller={controller} />
            </TemplateSheet>
        </Template>
    );

}

export default ItemJournal;

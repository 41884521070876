import _global from './global';
const config = require(`../config/${process.env.NODE_ENV}`).default;


 
const getPermission = (rule) => {
    const { type } = _global.getToken();
    // console.warn('perminssion::',typeof type, type.permissions);
    return type.permissions.includes(rule);
}

 
export default {
    getPermission
};
import { faCancel, faEnvelope, faLink, faPrint, faSave, faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';



const TemplateSheet = ({ children, controller }) => {
    const navigate = useNavigate();

    useEffect(() => {

        if (!localStorage.getItem('token')) {
            navigate('/login', { replace: false });
        }

    }, [])

    const closeBtn = () => {
        controller.setEditable(false);
        controller.setViewData(false)
    }

    const { t } = useTranslation();

    //console.log('template->form', typeof controller.form.items === 'undefined', controller.form, 'status->', controller.form.status);
    return (
        <div>
            {children}
            <div className={`row ${!controller.editable && 'd-none'} disabled-print`}>
                <div className="bottom-fixed ">
                    <div className="right ">
                        <div className="d-flex justify-content-end">
                            <button
                                className='btn btn-outline-info btn-sm ml-2 '
                                onClick={() => window.print()}>
                                <FontAwesomeIcon icon={faPrint} /> {t('print')}
                            </button>
                            <button
                                className={`btn btn-success btn-sm ml-2 ${controller.viewData ? 'd-none' : 'd-block'}`}
                                onClick={() => controller.create()}>
                                <FontAwesomeIcon icon={faSave} /> {t('save')}
                            </button>
                            <button
                                className={`btn btn-warning btn-sm ml-2 ${(controller.editable && (controller.form.status === 4 || !controller.viewData))
                                    ? 'd-none'
                                    : 'd-block'
                                    } `}
                                onClick={() => controller.modify()}>
                                <FontAwesomeIcon icon={faSave} /> {t('edit')}
                            </button>
                            <div className={` ${controller.disableApprove ? 'd-none' : ''}`}>
                                <button
                                    className={`btn btn-primary btn-sm ml-2 ${(controller.form.id <= 0 || controller.form.status === 4)
                                        ? 'd-none'
                                        : (
                                            (typeof controller.form.items === 'undefined' || controller.form.items.length <= 0)
                                                ? 'd-none'
                                                : (!controller.viewData ? 'd-none' : 'd-block')
                                        )}  `}
                                    onClick={() => controller.setConfirmApprove(true)}
                                > <FontAwesomeIcon icon={faEnvelope} /> {t('send approve')}
                                </button>
                            </div>
                            <button className={`btn btn-outline-secondary text-white btn-sm ml-2`}
                                onClick={() => closeBtn()}>
                                <FontAwesomeIcon icon={faTimes} /> {t('close')}
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default TemplateSheet;

import React, { useEffect, useState } from 'react';
import { Buffer } from 'buffer';
import Product from '../../api/products';
import Template from '../../component/common/template';
import Table from './table';
import ModalNew from '../../component/modalNew';
import { t } from 'i18next';
import _ from 'lodash';
import Waiting from '../../component/waiting';
import _global from '../../api/global';
import Transactions from '../../component/products/transaction';
import InventoryApi from '../../api/inventory';
import InventoryAssetsApi from '../../api/inventory_assets';
import moment from 'moment/moment';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

const Products = () => {
    const [loading, setLoading] = useState(false);
    const [products, setProducts] = useState(null);
    const [code, setCode] = useState('');
    const [name, setName] = useState('');
    const [id, setId] = useState(null);
    const [detail, setDetail] = useState('');
    const [price, setPrice] = useState(0);
    const [type, setType] = useState(0);
    const [categoryId, setCategoryId] = useState(1);
    const [status, setStatus] = useState('Active');
    const [files, setFiles] = useState("");
    const [fileName, setFileName] = useState('');
    const [tempFile, setTempFile] = useState('');
    const [ext, setExt] = useState("");
    const [location, setLocation] = useState("");
    const [description, setDescription] = useState("");
    const [prices, setPrices] = useState('');
    const [unit, setUnit] = useState('');
    const [itemSource, setItemSource] = useState('');
    const [searchItem, setSearchItem] = useState('');
    const [stock, setStock] = useState(0);
    const [noDataComponent, setNoDataComponent] = useState(t('There are no records to display'));
    const [stockTxn, setStockTxn] = useState([]);
    const [dateRange, setDateRange] = useState([null, null]);
    const [startDate, endDate] = dateRange;
    const [itemSearch, setItemSearch] = useState({});
    const [measuresList, setMeasuresList] = useState([]);
    const [form, setForm] = useState({});
    const [sourceType, setSourceType] = useState('remaining');

    const fetchData = async (searchText) => {
        try {
            let product = await Product.getProductList(searchText);
console.log('products::', product)
            // let items = [];
            // for (const item of product) {
            //     let create_by = await UserApi.get(item.create_by);
            //     let update_by = await UserApi.get(item.update_by);
            //     items.push({
            //         ...item,
            //         create_by_name: create_by?.f_name_th,
            //         update_by_name: update_by?.f_name_th
            //     })
            // }
            setProducts(product);

            /**
             * get measure
             */
            let measurment = await _global.client.get('/v1/unit');
            setMeasuresList(measurment.data)
        } catch (e) {
            console.error('error get product::', e);
            setNoDataComponent(t('Network Error'))
        }

    }

    const searchProduct = async (searchText) => {
        //console.log('search product::', searchText)
        _global.clearCached();
        setSearchItem(searchText)
        fetchData(searchText).catch(console.error);
    }


    useEffect(() => {
        localStorage.removeItem('cart-list');
        fetchData().catch(console.error);
        console.log('form::', form)
    }, [form])


    const deleteProduct = (id) => {

        let data = products.filter((e) => e.id !== id);
        setProducts(data);
        Product.remove(id)

        //console.log('new data::', products)
    }

    const submitForm = async (e) => {
        e.preventDefault();
        try {

            /**
             * Show loading
             */
            setLoading(true);
            if (!name) {
                throw new Error('please input name')
            }
            let body = {
                code: code,
                name: name,
                detail: detail,
                img: files,
                img_ext: ext,
                price: price,
                category: categoryId,
                status: status,
                item_type: type,
                location: location || 1,
                description: description,
                prices: JSON.stringify(prices),
                unit: form.unit,
                item_source: itemSource || 1,
                stock: stock,
                base_unit: form.base_unit
            }


            if (parseInt(id) > 0) {
                console.log(`>>> sent update /products/${id}`, JSON.stringify(body, null, 2))
                let response = await Product.client.patch(`/products/${id}`, body);
                setId(null);
                console.log(id !== '' ? true : false);
                
                if (response.data.status === 2) {
                    //console.log('items update::', response.data.data)
                    setTimeout(() => {
                        //$('body').find('button.close').click();
                        document.getElementById(`close-addModalNew-Assets`).click();
                        _global.clearCached();
                    }, 1000)


                    let index = _.findIndex(products, { id: id });
                    // Replace item at index using native splice
                    products.splice(index, 1, response.data.data);

                    setProducts(await _global.mapCategory(products));
                }
                closeModal();
                
            } else {
                let response = await Product.client.post(`/products`, body);

                //console.log('response::', response.data.data);
                if (response.data.status === 2) {
                    let newData = products;
                    newData.push(response.data.data)
                    setProducts(await _global.mapCategory(newData))

                    //console.log('items::', products)
                    setTimeout(() => {
                        setLoading(false);
                        //$('body').find('button.close').click();
                        document.getElementById(`close-addModalNew-Assets`).click();
                        _global.clearCached();
                    }, 1000)
                }
                closeModal();

            }

            setLoading(false);
        } catch (e) {
            warning('Error:: ' + e.message);
            //$('body').find('input.product-name').focus();
            //document.getElementById(`close-addModalNew-Assets`).click();
            _global.clearCached();
            setLoading(false);
        }
    };


    const warning = (message) => {
        alert(message);
    }


    const handleChange = e => {
        const fileReader = new FileReader();
        fileReader.readAsDataURL(e.target.files[0], "UTF-8");
        setExt(e.target.files[0].name.split('.').pop());

        fileReader.onload = e => {
            setFiles(Buffer.from(e.target.result, 'base64'));
        };

        setTempFile(URL.createObjectURL(e.target.files[0]));
        setFileName(e.target.files[0].name)
    };

    const closeModal = () => {
        /**
         * Clear data after close modal
         */
        setTempFile(null);
        setFileName("");
        setFiles("");
        setName('');
        setId(null);
        setDetail('');
        setDescription('');
        setPrice(0);
        setPrices({ [t('hours')]: 1 });
        setUnit({ 'unit': t('unit') })
        setType(0);
        setCategoryId(1);
        setLocation(1);
        setStatus('Active');
        setStock(0);
        setForm({});

        document.getElementById("close-addModalNew-Assets").click();
        _global.clearCached();
    }

    const cloneItem = async (item) => {
        try {
            setLoading(true);
            let body = {
                code: item.code,
                name: item.name,
                detail: item.detail,
                img: item.img,
                img_ext: 'jpg',
                price: item.price,
                category: item.category,
                status: item.status,
                item_type: item.type,
                location: item.location || location,
                description: item.description,
                prices: item.prices,
                unit: item.unit,
                clone: 1,
                base_unit: form.base_unit
            }

            let response = await Product.client.post(`/products`, body);

            //console.log('response clone::', response.data.data);
            if (response.data.status === 2) {
                let newData = products;
                newData.push(response.data.data)

                setProducts(await _global.mapCategory(newData));

                //console.log('items::', products)
                setTimeout(() => {
                    setLoading(false);
                    _global.clearCached();
                }, 500)
            }
        } catch (e) {
            console.error('error add item', e);
        }

    }

    const changedateFilterTxn = (update) => {
        setDateRange(update);
        let [startDate, endDate] = update;

        let date = new Date();
        let fromDefault = new Date(date.getFullYear(), date.getMonth(), date.getDate());
        let toDefault = new Date(date.getFullYear(), date.getMonth(), date.getDate() + 1);


        searchTxnInventory(startDate || fromDefault, endDate || toDefault, itemSearch, sourceType);

    }

    const searchTxnInventory = async (from, to, item, type = 'remaining') => {
        setLoading(true);
        setItemSearch(item);
        setSourceType(type);
        const invenClient = {
            'remaining': InventoryApi,
            'stock': InventoryAssetsApi
        }

        let res = await invenClient[type].searchByItem(
            moment(from).startOf('day').toISOString(),
            moment(to).add(23, 'hours').add(59, 'minutes').add(59.999, 'seconds').toISOString(),
            item.id
        );

        let txn = res.length && res.map((el) => {
            return {
                ...el,
                payload: JSON.parse(el.payload)
            }
        })

        let sort = [];
        if (txn.length) {
            sort = [...txn].sort((a, b) => {
                var c = new Date(a.datetime);
                var d = new Date(b.datetime);
                return d - c;
            });
        }

        //console.log('txn::', sort)
        setStockTxn(sort);
        setLoading(false);
    }

    const controller = {
        modal: {
            mode: '',
            bg: '',
            title: t('stock transaction'),
            submitForm: () => { },
            openModal: searchTxnInventory
        },
        form,
        setForm,
        measuresList: measuresList
    }

    return (
        <Template >
            <Waiting loading={loading} />
            <Transactions controller={controller} >
                <div className="row">
                    <div className="col-6 text-right">
                        <div className='mt-1'>{t('date filter')}</div>
                    </div>
                    <div className="col-6 text-right">
                        <DatePicker
                            selectsRange={true}
                            startDate={startDate}
                            endDate={endDate}
                            onChange={(update) => {
                                changedateFilterTxn(update);
                            }}
                            isClearable={true}
                            className="form-control form-control-sm"
                            placeholderText={t('date filter')}
                            dateFormat="yyyy-MM-dd"
                            withPortal
                        />
                    </div>
                    <div className="col">
                        <table className='table table-bordered font-13 mt-2'>
                            <thead>
                                <tr>
                                    <th>{t('document no')}</th>
                                    <th>{t('Detail')}</th>
                                    <th className='text-right'>{t('qty')}</th>
                                    <th className='text-center'>{t('time')}</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    stockTxn.length > 0 && stockTxn.map((el, i) => (
                                        <tr key={i + 1} className={`${(el.decrease * 1 || el.increase) < 0 ? 'text-danger' : ''}`}>
                                            <td className=' '>{el.payload?.source_no}</td>
                                            <td className=' '>{el.payload?.source_des}</td>
                                            <td className={`text-right `}>
                                                <b>{parseInt(el.decrease * 1 || el.increase)}</b>
                                            </td>
                                            <td className='text-center'>
                                                <div>{moment(el.datetime).format('H:mm')}</div>
                                                <div>{moment(el.datetime).format('DD/MM/YYYY')}</div>
                                            </td>
                                        </tr>
                                    ))
                                }
                            </tbody>
                        </table>
                    </div>
                </div>
            </Transactions>
            <ModalNew submitForm={submitForm} ModalName={t('Product')} name="Assets">
                <Product.AddProduct
                    handleChange={handleChange}
                    files={files}
                    fileName={fileName}
                    tempFile={tempFile}
                    name={name}
                    id={id}
                    code={code}
                    setCode={setCode}
                    detail={detail}
                    price={price}
                    prices={prices}
                    unit={unit}
                    status={status}
                    type={type}
                    categoryId={categoryId}
                    location={location}
                    setPrice={setPrice}
                    setPrices={setPrices}
                    setUnit={setUnit}
                    setName={setName}
                    setDetail={setDetail}
                    setCategoryId={setCategoryId}
                    setStatus={setStatus}
                    setType={setType}
                    setLocation={setLocation}
                    description={description}
                    setDescription={setDescription}
                    setItemSource={setItemSource}
                    itemSource={itemSource}
                    stock={stock}
                    setStock={setStock}
                    controller={controller}
                />
            </ModalNew>
            <Table
                data={products}
                noDataComponent={noDataComponent}
                setNoDataComponent={setNoDataComponent}
                deleteProduct={deleteProduct}
                setName={setName}
                setDetail={setDetail}
                setId={setId}
                setPrice={setPrice}
                categoryId={categoryId}
                setCategoryId={setCategoryId}
                setStatus={setStatus}
                setType={setType}
                setLocation={setLocation}
                setDescription={setDescription}
                setTempFile={setTempFile}
                setFileName={setFileName}
                setPrices={setPrices}
                setUnit={setUnit}
                cloneItem={cloneItem}
                setItemSource={setItemSource}
                searchItem={searchItem}
                searchProduct={searchProduct}
                setCode={setCode}
                setStock={setStock}
                controller={controller}
                t={t}
            />
        </Template>
    );
}

export default Products;

import React, { useEffect, useState } from 'react';
import _ from 'lodash';
import { useTranslation } from 'react-i18next';
import itemProperty from '../../api/itemProperty';
import ModalNew from '../modalNew';
import ModalImage from '../modalImages';
import ModalConfirm from '../modalConfirm';
import Waiting from '../waiting';
import { Buffer } from 'buffer';
import _global from '../../api/global';
import product from '../../api/products';

function ImageUploads({ controller }) {
    const { t } = useTranslation();
    let { item } = controller
    const [loading, setLoading] = useState(false);

    const [ext, setExt] = useState("");
    const [fileName, setFileName] = useState('');
    // const [itemProp, setItemProp] = useState(null);
    // const [itemPropGroup, setItemPropGroup] = useState([]);
    const [formItemProp, setFormItemProp] = useState({ group_name: '', attr_name: '', attr_value: '' });
    const [deleteItemProp, setDeleteItemProp] = useState({ id: '', name: '' });

    let { group_code, id, modify } = controller

    useEffect(() => {
        //fetchItemProps(id).catch(console.error);
    }, []);

    const handelChangeProperties = el => {
        //console.warn('el::',el.target.files[0])
        if (el.target.name === 'att_file') {
            const fileReader = new FileReader();
            fileReader.readAsDataURL(el.target.files[0], "UTF-8");
            setExt(el.target.files[0].name.split('.').pop());
            setFileName(el.target.files[0].name);

            fileReader.onload = element => {
                setFormItemProp({
                    ...formItemProp,
                    [el.target.name]: element.target.result
                })
            };
        }

        setFormItemProp({
            ...formItemProp,
            [el.target.name]: el.target.value
        })
    };

    const confirmDelete = async (el) => {
        setDeleteItemProp({ id: el.target.id, name: el.target.name });
    }

    const submitForm = async (el) => {
        el.preventDefault();
        setLoading(true);
        //console.warn('formItemProp::', fileName, ext);
        //console.warn('itemProp::', itemProp);
        try {
            // Attach
            let fileBody = {
                title: fileName.toString(),
                files: Buffer.from(formItemProp.att_file, 'base64'),
                ext: ext,
                setting: JSON.stringify({
                    name: fileName.toString(),
                    ext: ext
                })
            }
            const { data: response } = await _global.client.post("/v1/file", fileBody);
            console.log('<< upload::', response);
            if (response.status === 2) {
                //=> callback
                controller.callBack(response.data);

                setTimeout(() => {
                    setLoading(false);
                    document.getElementById(`close-addModalNew-${group_code}`).click();
                }, 800);
            }
        } catch (e) {
            setLoading(false);
            if (e.code === 'warn') {
                console.warn(e.message);
            } else {
                console.error('error add::', e);
            }
        }
    };

    const imagePopup = async (el) => {
        el.preventDefault();
        setLoading(true);
    }

    const deleteAttr = async () => {
        //setLoading(true);
        console.log('deleteItemProp::', deleteItemProp)
        try {

            let newImages = [];
            for (let i = 0; i < item.images.length; i++) {
                if (i !== Number(deleteItemProp.id)) {
                    newImages.push(item.images[i]);
                }
            }

            console.info('=>', newImages);
            //=> callback
            controller.updateImages(newImages);
            // update item

            document.getElementById(`close-addModalConfirm-${group_code}`).click();
            setTimeout(() => {
                setLoading(false);
            }, 800);
        } catch (e) {
            console.error('error remove::', e);
        }
    };

    return (
        <div className='row mt-5'>
            <Waiting loading={loading} />
            <ModalNew submitForm={submitForm} ModalName={t('Image')} screen='modal-center' name={group_code}>
                <div className='row'>
                    <div className='row'>
                        <div className='col-12 px-5'>
                            <div className="custom-file">
                                <input
                                    type="file"
                                    className="custom-file-input"
                                    id="customFile"
                                    name={'att_file'}
                                    onChange={(el) => handelChangeProperties(el)}
                                />
                                <label
                                    className="custom-file-label text-info"
                                    htmlFor="customFile">{formItemProp.att_file || t('Choose image...')}</label>
                            </div>
                        </div>
                    </div>
                </div>
            </ModalNew>
            <ModalConfirm submitConfirm={deleteAttr} ModalName={t('Confirm')} screen={'bd-example-modal-md'} name={group_code}>
                {t(`Delete "${deleteItemProp.name}" ?`)}
            </ModalConfirm>



            <div className='font-sm'>Picture</div>
            <div className='row mt-4'>
                {
                    item?.images ?
                        item?.images.map((el, index) =>
                            <div key={index} className='col-3 mb-3' style={{ position: 'relative' }}>
                                <div style={{ maxWidth: '100%' }}
                                    className='border border-2 shadow-5'
                                >
                                    <ModalImage submitForm={imagePopup} ModalName={t('Image')} screen='modal-center' name={`${group_code}-${index}`}>
                                    <img
                                            src={`${_global.config.api}/uploads/${(el)}`}
                                            className="img-fluid " name={el}
                                            alt={el}
                                            style={{ maxHeight: '100%' }}
                                        />
                                    </ModalImage>
                                    <div className='text-decoration-none cursor-pointer'
                                        id={el}
                                        onClick={() => setLoading(false)}
                                        data-toggle="modal"
                                        data-target={`#addModalImage-${group_code}-${index}`}
                                    >
                                        <img
                                            src={`${_global.config.api}/uploads/${(el)}`}
                                            className="img-fluid " name={el}
                                            alt={el}
                                            style={{ maxHeight: '100%' }}
                                        />
                                    </div>

                                </div>
                                {
                                    modify
                                        ? <div style={{
                                            position: 'absolute',
                                            top: '1%',
                                            right: '5%',
                                            zIndex: 2
                                        }}>
                                            <button
                                                className='btn btn-sm btn-outline-danger text-warn'
                                                id={index}
                                                name={el}
                                                onClick={(el) => confirmDelete(el)}
                                                data-toggle="modal"
                                                data-target={`#addModalConfirm-${group_code}`}
                                            >x</button>
                                        </div>
                                        : ''
                                }

                            </div>
                        )
                        :
                        <img
                            src={`${_global.config.api}/images/items/${(item?.img)?.split(".")[0]}/${(item?.img)?.split(".")[1] || 'jpg'}/small`}
                            className="img-fluid p-1 shadow-5 w-100" name={item?.img}
                            alt={item?.alt || item?.name} id={item?.id}
                        />
                }
            </div>
            {
                modify
                    ? <div className='mx-3 mt-4'>
                        <button
                            className='btn btn-warning btn-xs rounded-pill'
                            onClick={() => setLoading(false)}
                            data-toggle="modal"
                            data-target={`#addModalNew-${group_code}`}
                        >+ {t('Upload')}</button>
                    </div>
                    : ''
            }
        </div>
    )
}

export default ImageUploads;

